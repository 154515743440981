import { degrees, PDFDocument } from 'pdf-lib';
import { UploadPdfAction } from '@property-folders/contract';
import { Predicate } from '../../predicate';

export * from './pdf-information-extractor';
export * from './signing-location-injection';
export * from './font-embedder';

type PdfData = Uint8Array | ArrayBuffer;
export async function stitchToDoc(doc: PDFDocument, pdfs: PdfData[]) {
  if (!pdfs.length) return;

  for (const pdf of pdfs) {
    const docToInclude = await PDFDocument.load(pdf);
    const count = docToInclude.getPageCount();
    const pages = await doc.copyPages(docToInclude, [...new Array(count).keys()]);

    for (const page of pages) {
      doc.addPage(page);
    }
  }

  return doc.save();
}

export type ChangeFn = (document: PDFDocument) => Promise<void>;
export async function applyPdfChanges(base: PdfData, changes: ChangeFn[]): Promise<PdfData> {
  if (!changes.length) return base;

  const doc = await PDFDocument.load(base);

  for (const change of changes) {
    await change(doc);
  }

  return doc.save();
}

export function makeUploadPdfChangeFns(actions: UploadPdfAction[], destructive?: boolean): ChangeFn[] {
  const result: ChangeFn[] = actions.map(action => {
    switch (action.action) {
      case 'rotate_cw':
        return async (doc: PDFDocument) => {
          if (action.pageIndex < doc.getPageCount()) {
            const page = doc.getPage(action.pageIndex);
            page.setRotation(degrees(page.getRotation().angle + 90));
          }
        };
      case 'rotate_ccw':
        return async (doc: PDFDocument) => {
          if (action.pageIndex < doc.getPageCount()) {
            const page = doc.getPage(action.pageIndex);
            page.setRotation(degrees(page.getRotation().angle - 90));
          }
        };
      default:
        return undefined;
    }
  }).filter(Predicate.isNotNull);

  // apply deletes after non-destructive actions to not interfere with index-based page accesses
  if (destructive) {
    for (const action of actions) {
      if (action.action !== 'delete') continue;
      result.push(async (doc: PDFDocument) => {
        if (action.pageIndex < doc.getPageCount()) {
          doc.removePage(action.pageIndex);
        }
      });
    }
  }

  return result;
}
