
import { DocumentFieldType, PathSegments, ValidationDefnType } from '@property-folders/contract/yjs-schema/model';
import { generateParentPath, getValidationDefnByPath, getValueByPath } from '@property-folders/common/util/pathHandling';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { Predicate } from '../predicate';

function findAllReadOnlyPaths(rules: ValidationDefnType, currentPath: PathSegments = []): PathSegments[] {
  const res = [];
  if (rules._readOnly) res.push(currentPath);
  for (const ruleKey in rules) {
    if (ruleKey !== '_children' && ruleKey.startsWith('_')) continue;
    if (ruleKey === '_children') {
      res.push(...findAllReadOnlyPaths(rules[ruleKey], [...currentPath, '*']));
      continue;
    }
    res.push(...findAllReadOnlyPaths(rules[ruleKey], [...currentPath, ruleKey]));
  }
  return res;
}

export function restoreSnapshotPath(
  snapshot: MaterialisedPropertyData,
  inplaceLiveData: MaterialisedPropertyData,
  path: PathSegments,
  fieldRules: DocumentFieldType
) {
  // As with the comment in the test file, we are not restoring whole array elements because
  // something within it is marked read only. The Vendor Type of a delete Vendor is no longer
  // relevant to us. So if we can't find the path, that's it.
  const roValue = getValueByPath(path, snapshot, true);
  let workingParent: any = inplaceLiveData;
  let workingSnapshotParent: any = snapshot;
  const parentSegs = generateParentPath(path);
  for (const segmentI in parentSegs) {
    const segment = parentSegs[segmentI];

    // It'd be nice to just create a structure with only the snapshotted data that's readonly and
    // use that. However, we have IDs in our array elements, and even just having array data
    // is going to make something like fast json patch have problems I imagine.
    if (segment === '*' && Array.isArray(workingSnapshotParent) && Array.isArray(workingParent) ) {
      const lockedIds = workingSnapshotParent.map(ws=>ws.id);
      workingParent.forEach(live => {
        if (lockedIds.includes(live.id)) {
          restoreSnapshotPath(
            workingSnapshotParent.find(s=>s.id === live.id),
            live,
            path.slice(parseInt(segmentI)+1),
            getValidationDefnByPath(path.slice(0,parseInt(segmentI)+1), fieldRules)
          );
        }
      });
      return;
    }

    const proposedLiveParent = workingParent?.[segment];

    if (workingSnapshotParent != null) workingSnapshotParent = workingSnapshotParent[segment];
    if (roValue != null && proposedLiveParent == null && workingParent != null) {
      // read rules and create the correct structure.
      const type = getValidationDefnByPath(path.slice(0,parseInt(segmentI)+1), fieldRules)._type;
      // We are not restoring Arrays here, array items are contextual, and the context being lost
      // means the readonly contents of that array have no meaning and shouldn't be preserved
      if (type === 'Map') {
        workingParent[segment] = {};
        workingParent = workingParent[segment];
      }
    } else {
      workingParent = proposedLiveParent;
    }

  }
  const finalPathSegment = path[path.length-1];
  if (finalPathSegment === '*' && Array.isArray(workingParent) && Array.isArray(workingSnapshotParent)) {
    // readonly on list children doesn't mean restore all the children, it means the list identities
    // are immutable, no adding or removing.
    // Expects an id, if there's no id, this would only make sense with strings I guess?
    const replacementList = workingSnapshotParent.map(rid=>{
      const result = workingParent.find(ws=>(ws.id??ws)===(rid.id??rid));
      if (result) return result;
      return rid;
    }).filter(Predicate.isNotNullish);
    workingParent.splice(0,workingParent.length, ...replacementList);
    return;
  }

  if (finalPathSegment === '*') {
    console.warn('No array for a path which expects and array');
    return;
  }

  if (
    (workingSnapshotParent == null || workingSnapshotParent?.[finalPathSegment] == null)
    && workingParent && typeof workingParent === 'object' && finalPathSegment in workingParent
  ) {
    delete workingParent[finalPathSegment];
    return;
  }
  if (workingSnapshotParent?.[finalPathSegment] != null) {
    workingParent[finalPathSegment] = workingSnapshotParent[finalPathSegment];
    return;
  }
}

export function spliceReadonlyData(
  rules: ValidationDefnType | undefined,
  snapshot: MaterialisedPropertyData | undefined,
  inplaceLiveData: MaterialisedPropertyData | undefined,
  fields: DocumentFieldType | undefined
): MaterialisedPropertyData | undefined {
  if (!rules || inplaceLiveData == null || snapshot == null || fields == null) return inplaceLiveData;
  // I did consider a parallel resolver of paths so we could parse the tree in one go, however, it
  // occured to me that I should probably recover items which are readonly and have been deleted
  const allRules = findAllReadOnlyPaths(rules);
  for (const path of allRules) {
    restoreSnapshotPath(snapshot, inplaceLiveData, path, fields);
  }

  return inplaceLiveData;
}