import { LinkBuilder } from '../util/LinkBuilder';
import { ContentType } from '@property-folders/contract';
import { WrappedFetch } from './wrappedFetch';

export class ReaformsUserAssets {
  public static async getEntityLogo(entityId: number) {
    // should be either an image or HTTP 307 to pre-signed url
    const result = await WrappedFetch.bare(LinkBuilder.restApi(`/entities/${entityId}/logo`));
    return {
      data: await result.blob(),
      contentType: result.headers.get('content-type') || ContentType.Jpeg
    };
  }
}
