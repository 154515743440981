import { byMapperFn } from './sortComparison';
import { ArrayUtil } from './array';

export type GetPageFn<T> = (item: T) => number;
export type GetPositionFn<T> = (item: T) => { x: number, y: number };
export type GetDistanceThresholdFn<T> = (items: T[]) => number;

export function positionSort<T>(
  items: T[],
  getPosition: GetPositionFn<T>,
  getDistanceThreshold: GetDistanceThresholdFn<T>,
  desc = false
) {
  const result = new Array<T>();
  let toSearch = [...items];

  while (toSearch.length) {
    toSearch.sort(byMapperFn(t => {
      const pos = getPosition(t);
      return pos.x + pos.y;
    }, desc ? 'desc' : 'asc'));
    const top = toSearch[0];
    const yLine = getPosition(top).y;
    const distanceThreshold = Math.abs(getDistanceThreshold([top]));

    const remaining = new Array<T>();
    const row = new Array<T>();

    for (const item of toSearch) {
      const pos = getPosition(item);
      const distance = Math.abs(pos.y - yLine);
      if (distance <= distanceThreshold) {
        row.push(item);
      } else {
        remaining.push(item);
      }
    }

    row.sort(byMapperFn(t => getPosition(t).x));
    result.push(...row);
    toSearch = remaining;
  }

  return result;
}

export function positionSortMultiPage<T>(
  items: T[],
  getPage: GetPageFn<T>,
  getPosition: GetPositionFn<T>,
  getDistanceThreshold: GetDistanceThresholdFn<T>,
  desc = false
) {
  const pageGroups = ArrayUtil.groupBy(
    // pre-sort by page, as Map will preserve page order, making emitting a sorted result easier
    items.sort(byMapperFn(item => getPage(item))),
    getPage
  );

  for (const page of pageGroups.keys()) {
    const group = pageGroups.get(page);
    if (group) {
      pageGroups.set(page, positionSort(group, getPosition, getDistanceThreshold, desc));
    } else {
      pageGroups.delete(page);
    }
  }

  return [...pageGroups.values()].flat();
}
