import { isValidTimeZone } from './formatting';

export class DateFunctions {
  static Yesterday() {
    return new Date(Date.now() - 86400000);
  }

  static GetTimeZoneOffset(tz: string, hereDate?: Date) {
    hereDate = new Date(hereDate || Date.now());
    hereDate.setMilliseconds(0); // for nice rounding

    const hereOffsetHrs = hereDate.getTimezoneOffset() / 60 * -1;
    const thereLocaleStr = hereDate.toLocaleString('en-US', { timeZone: tz });
    const thereDate = new Date(thereLocaleStr);
    const diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60;

    return hereOffsetHrs + diffHrs;
  }

  static getPartsForTimezone(time: Date | number, timeZone: string, parseNumber: false): {year: string, month: string, day: string, hour: string, minute: string, second: string };
  static getPartsForTimezone(time: Date | number, timeZone: string, parseNumber?: true | undefined): {year: number, month: number, day: number, hour: number, minute: number, second: number };
  static getPartsForTimezone(time: Date | number, timeZone: string = 'Australia/Adelaide', parseNumber = true) {
    if (typeof time === 'number') {
      time = new Date(time);
    }
    const formatter = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone
    });
    const pairs = formatter.formatToParts(time);
    return Object.assign({}, ...[...pairs].map(({ type: key, value: val }) => {
      if (parseNumber) {
        const value = parseInt(val);
        if (isNaN(value)) return {};
        return { [key]: value };
      }
      return { [key]: val };
    }));

  }

  static isoDateFormatInTz(time: Date | number , timeZone: string = 'Australia/Adelaide') {
    const parts = DateFunctions.getPartsForTimezone(time, timeZone, false);
    return `${parts.year}-${parts.month}-${parts.day}`;
  }

  static ConstrainMsToReasonableHours(ms: number, timeZone: string = 'Australia/Adelaide'): number {
    const date = new Date(ms);

    if (!isValidTimeZone(timeZone)) {
      timeZone = 'Australia/Adelaide';
    }

    const timeZoneOffset = this.GetTimeZoneOffset(timeZone, date);
    const timeZoneOffsetHours = Math.floor(timeZoneOffset);
    const timeZoneOffsetMinutes = (timeZoneOffset * 60) % 60;

    let proposedHourInTz = date.getUTCHours() + (date.getUTCMinutes() / 60) + timeZoneOffset;
    let dayAdjustment = 0;
    if (proposedHourInTz > 23) {
      proposedHourInTz -= 24;
      dayAdjustment = 1;
    } else if (proposedHourInTz < 0) {
      proposedHourInTz += 24;
      dayAdjustment = -1;
    }

    if (proposedHourInTz < 8) { // 8am
      date.setUTCHours(8 - timeZoneOffsetHours);
    } else if (proposedHourInTz > 20) { // 8pm
      date.setUTCHours(8 - timeZoneOffsetHours);
      date.setUTCDate(date.getUTCDate() + 1);
    } else {
      return date.getTime();
    }

    if (timeZoneOffsetMinutes) {
      date.setUTCMinutes(-timeZoneOffsetMinutes);
    }

    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);

    if (dayAdjustment !== 0) {
      date.setUTCDate(date.getUTCDate() + dayAdjustment);
    }

    return date.getTime();
  }
}
