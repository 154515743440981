// https://stackoverflow.com/a/3943023
export function w3cFontSelection(hex: string) {
  if (!hex) {
    return '#000000';
  }

  if (hex.startsWith('#')) {
    hex = hex.substring(1);
  }

  if (hex.length !== 6 && hex.length !== 3) {
    throw new Error('Invalid hex supplied');
  }

  if (hex.length === 3) {
    const rHex = hex[0];
    const gHex = hex[1];
    const bHex = hex[2];
    hex = '' + rHex.repeat(2) + gHex.repeat(2) + bHex.repeat(2);
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
    ? '#000000'
    : '#ffffff';
}
