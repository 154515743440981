import { IFileProvider } from '@property-folders/contract';

export class SingleBlobFileProvider implements IFileProvider {
  constructor(private blob: Blob) { }
  async getFile(): Promise<Uint8Array | undefined> {
    return new Uint8Array(await this.blob.arrayBuffer());
  }

  async getBlob(id: string) {
    return this.blob;
  }
}

export class SingleArrayBufferFileProvider implements IFileProvider {
  constructor(private buffer: ArrayBuffer) { }
  async getFile(): Promise<Uint8Array | undefined> {
    return new Uint8Array(this.buffer);
  }

  async getBlob(id: string) {
    return new Blob([this.buffer]);
  }
}
