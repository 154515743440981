
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';

export class SubscriptionFormApi {
  public static async cloneSelfPreparedForm1(propertyId: string, instanceFormId: string) {
    return await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/subscription-form/${instanceFormId}/clone-self-prepared-form1`), {
      method: 'POST'
    });
  }
}
