import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import {
  GetPropertyAuditEntriesRequestQuery,
  GetPropertyAuditEntriesResult,
  GetPropertyAuditAuthorsRequestQuery,
  GetPropertyAuditAuthorsResult, GetPropertyAuditFormFamiliesResult, GetSigningSessionHistoryResult
} from '@property-folders/contract/rest/property';

export class AuditApi {
  public static getPropertyAuditEntries(propertyId: string, queryRaw: {
    startKey?: string,
    before?: number,
    formFamily?: string,
    type?: 'all' | 'content' | 'signing' | 'notification',
    authorIds?: string,
    signingSessionId?: string
  }) {
    const query: GetPropertyAuditEntriesRequestQuery = {
      before: queryRaw.before?.toString(),
      type: queryRaw.type || 'all',
      authorIds: queryRaw.authorIds,
      startKey: queryRaw.startKey,
      formFamily: queryRaw.formFamily,
      signingSessionId: queryRaw.signingSessionId
      // raw: true
    };
    const qs = LinkBuilder.buildQueryString(query);
    return WrappedFetch.json<GetPropertyAuditEntriesResult>(LinkBuilder.restApi(`/properties/${propertyId}/audit-entries?${qs}`));
  }

  public static getPropertyAuditAuthors(propertyId: string, query: GetPropertyAuditAuthorsRequestQuery) {
    const qs = LinkBuilder.buildQueryString(query);
    return WrappedFetch.json<GetPropertyAuditAuthorsResult>(LinkBuilder.restApi(`/properties/${propertyId}/audit-authors?${qs}`));
  }

  public static getPropertyAuditFormFamilies(propertyId: string) {
    return WrappedFetch.json<GetPropertyAuditFormFamiliesResult>(LinkBuilder.restApi(`/properties/${propertyId}/audit-form-families`));
  }

  public static getSigningSessionHistory(propertyId: string, signingSessionId: string) {
    const abortController = new AbortController();
    return {
      abort: () => {
        abortController.abort();
      },
      response: WrappedFetch.json<GetSigningSessionHistoryResult>(
        LinkBuilder.restApi(`/properties/${propertyId}/signing-sessions/${signingSessionId}/history`),
        { signal: abortController.signal }
      )
    };
  }
}
