import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import { getPageInfo } from '../util/pdf';
import { CoordinateMath, NoReflection } from '../util/coords';

async function drawFullPageWatermark(text: string, doc: PDFDocument, opts?: { size?: number, colorRgb?: [number, number, number], opacity?: number}) {
  const { size = 30, colorRgb = [0,0,0], opacity = 0.25 } = opts??{};
  try {
    const font = await doc.embedFont(StandardFonts.Helvetica);
    doc.getPages().forEach(page => {

      const { dims } = getPageInfo(doc, page);
      const { rotatedHeight, rotatedWidth } = dims;
      const textPoint = CoordinateMath.orientToPage({
        x: (rotatedWidth / 2) - (font.widthOfTextAtSize(text, size) * 0.25),
        y: (rotatedHeight / 2) - (font.widthOfTextAtSize(text, size) * 0.42)
      }, dims, NoReflection);
      page.drawText(text, {
        x: textPoint.x,
        y: textPoint.y,
        font: font,
        size: size,
        color: rgb(...colorRgb),
        opacity,
        rotate: degrees(dims.degrees+50)
      });
    });
    return true;
  } catch (error) {
    console.error('Error in drawWatermark: ', error);
    return false;
  }
}

export async function applyPreviewWatermark(doc: PDFDocument) {
  await drawFullPageWatermark('Template', doc, { size: 180, opacity: 0.1 });
}
