import { determineFormState, FormDescriptorRecord } from '../../yjs-schema/property';
import { FormStates } from '@property-folders/contract';
import { FormTypes } from '../../yjs-schema/property/form';

export function formDependenciesMet(def: FormDescriptorRecord, formStates: FormStates) {
  if (!def?.suggestion) {
    // if there are no dependencies, then the form's dependencies are met, right?
    return true;
  }

  const suggestions = Object.entries(def.suggestion);
  const unmetSuggestions = suggestions.filter(([formCode, stateChecks]) => {
    const parentDefn = FormTypes[formCode]?.formFamily;
    const instances = formStates?.[parentDefn]?.instances || [];

    if (!instances.length) {
      return true;
    }

    const instance = instances[0];
    return !(determineFormState(instance) in stateChecks);
  });

  return unmetSuggestions.length === 0;
}
