import { isValidTimeZone } from './isValidTimeZone';

export function getTimeString(timestamp?: number, timeZone?: string, prefix = 'since', timeOnly?: boolean, dateOnly?: boolean) {
  if (!timestamp) return undefined;

  // return 'since time on date';
  try {
    // DD-MMM-yyyy HH:mm (TZ)
    // 12-Jan-2023 09:45 (ACST)
    const formatter = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      hourCycle: 'h23',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone: timeZone && isValidTimeZone(timeZone)
        ? timeZone
        : 'Australia/Adelaide'
    });
    const parts = formatter.formatToParts(new Date(timestamp));
    const bits: Intl.DateTimeFormatPartTypesRegistry = Object.assign({}, ...parts.map(p => ({ [p.type]: p.value })));

    if (timeOnly) {
      return `${bits.hour}:${bits.minute}`;
    }

    if (dateOnly) {
      return `on ${bits.day} ${bits.month} ${bits.year}`;
    }

    return `${prefix} ${bits.hour}:${bits.minute} on ${bits.day} ${bits.month} ${bits.year}`.trim();
  } catch {
    return '';
  }
}
