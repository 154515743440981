export class NumberUtil {
  public static clamp(value: number, min?: number, max?: number): number {
    if (min != null && value < min) {
      return min;
    }

    if (max != null && value > max) {
      return max;
    }

    return value;
  }
}
