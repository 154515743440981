class Point {
  constructor(
    public x: number,
    public y: number,
    private fnChoose: (a: number, b: number) => number,
  ) { }

  processNew(x: number, y: number) {
    this.x = this.fnChoose(x, this.x);
    this.y = this.fnChoose(y, this.y);
  }
}

export function trimCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  const width = canvas.width;
  const height = canvas.height;
  const imgData = context.getImageData(0, 0, width, height).data;
  const topLeft = new Point(width, height, Math.min);
  const bottomRight = new Point(0, 0, Math.max);

  const channelCount = 4;
  const alphaChannelOffset = 3;
  const getAlpha = (x: number, y: number) => {
    const idxPixel = (width * y + x) * channelCount;
    return imgData[idxPixel + alphaChannelOffset];
  };

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      if (getAlpha(x, y)) {
        topLeft.processNew(x, y);
        bottomRight.processNew(x, y);
      }
    }
  }

  const newWidth = 1 + bottomRight.x - topLeft.x;
  const newHeight = 1 + bottomRight.y - topLeft.y;
  const trimmedData = context.getImageData(topLeft.x, topLeft.y, newWidth, newHeight);
  canvas.width = newWidth;
  canvas.height = newHeight;
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.putImageData(trimmedData, 0, 0);
}
