import {
  ContentType,
  FileRef,
  IFileProvider,
  MaterialisedPropertyData,
  SigningSessionFieldType,
  SigningSessionSubType,
  TransactionMetaData
} from '@property-folders/contract';
import { PDFCheckBox, PDFDocument, PDFField, PDFImage, PDFRadioGroup, PDFTextField, rgb, StandardFonts } from 'pdf-lib';
import { canonicalisers, formatTimestamp, parseInt2 } from '../util/formatting';
import { buildFieldName, FieldType, parseFieldName, ServeField, SystemField } from './pdf-form-field';
import { createCanvas, loadImage } from 'canvas';
import { Dimension, drawCompoundSignatureWithinDimensions } from './draw-compound-signature';
import { Predicate } from '../predicate';
import { selectiveFlatten } from './selective-flatten';
import { backOff } from 'exponential-backoff';
import { FileLoadError, MissingFieldDefinitionError, MissingPartyDetailsError } from './errors';

type PdfEmbeddableImage = Parameters<PDFDocument['embedPng']>[0];

async function loadImageFromBlob(blob: Blob) {
  if (typeof window === 'undefined') {
    return await loadImage(Buffer.from(await blob.arrayBuffer()));
  }

  const url = URL.createObjectURL(blob);
  try {
    return await loadImage(url);
  } finally {
    URL.revokeObjectURL(url);
  }
}

export interface FillFieldDefinition {
  id: string;
  type: SigningSessionFieldType;
  subtype?: SigningSessionSubType;
  file?: FileRef;
  isWetSigned?: boolean;
  text?: string;
  timestamp: number;
  // name or authority text
  name?: string;
}

export interface FillFormServeData {
  purchaser: {
    name: string;
    address: string;
    contractDate?: string
  }
}

export class PdfFormFiller {
  private doc?: PDFDocument;
  private imageCache = new Map<string, PDFImage>();
  private systemFieldCache = new Map<SystemField, string>();

  constructor(
    private baseFileId: string,
    private fileProvider: IFileProvider,
    private timeZone: string,
    private allowPartial: boolean,
    private propertyData?: MaterialisedPropertyData,
    private propertyMeta?: TransactionMetaData,
    private signingContext?: {
      completedAtMs?: number
    },
    private serveData?: FillFormServeData
  ) { }

  public async getBytes(opts?: {
    titleTransformer?: (original?: string) => string,
    producer?: string
  }): Promise<Uint8Array> {
    const producer = opts?.producer || 'reaforms Document Service';
    const titleTransformer = opts?.titleTransformer;

    const doc = await this.getDoc();

    doc.setProducer(producer);
    if (titleTransformer) {
      doc.setTitle(titleTransformer(doc.getTitle()), { showInWindowTitleBar: true });
    }

    return await doc.save();
  }

  public async addSigningWatermark(signingSessionId: string, counterpart?: { number: number, total: number }) {
    const pdfDoc = await this.getDoc();
    if (!pdfDoc) {
      return;
    }

    const executedTimestamp = formatTimestamp(this.signingContext?.completedAtMs, this.timeZone);

    return PdfFormFiller.addWatermark(pdfDoc, {
      signingSessionId,
      executedTimestamp,
      counterpart
    });
  }

  static async addWatermark(pdfDoc: PDFDocument, opts: {
    signingSessionId: string;
    executedTimestamp: string;
    counterpart?: {
      number: number;
      total: number;
    }
  }) {
    const { signingSessionId, executedTimestamp, counterpart } = opts;

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const totalPages = pdfDoc.getPageCount();

    const x = 10;
    const y = 8;
    const padding = 3;
    const fontSize = 8;

    let pageNum = 1;

    for (const page of pdfDoc.getPages()) {
      const text = `Greatforms Signing Session ID: ${signingSessionId}, Executed: ${executedTimestamp}, Page ${pageNum} of ${totalPages}${counterpart ? `, as counterpart ${counterpart.number} of ${counterpart.total}` : ''}`;
      const height = page.getHeight();
      const textWidth = font.widthOfTextAtSize(text, fontSize);
      const textHeight = font.heightAtSize(fontSize);

      page.drawRectangle({
        x: x,
        y: height - textHeight - y - (padding * 2),
        width: textWidth + (padding * 2),
        height: textHeight + (padding * 2),
        color: rgb(1, 1, 1)
      });

      page.drawText(text, {
        x: x + padding,
        y: height - textHeight - y - padding,
        size: fontSize,
        color: rgb(0.2, 0.2, 0.2)
      });
      pageNum++;

    }
  }

  public async fill(fieldDefinitions: FillFieldDefinition[], opts?: { partyTimestamps: Map<string, number>, noFlatten?: boolean, requestFileFillNotFound?: (file: FileRef) => void }) {
    const { requestFileFillNotFound } = opts ?? {};
    const pdfDoc = await this.getDoc();
    const form = pdfDoc.getForm();
    const pdfFields = form.getFields();

    const compositeSignatureDimensions = fieldDefinitions
      .filter(fd => fd.type === SigningSessionFieldType.Signature && fd.subtype === SigningSessionSubType.RenderInfoInline)
      .map(fd => {
        const fieldName = buildFieldName({ type: FieldType.FieldValue, fieldId: fd.id });
        const pdfField = pdfFields.find(pdfField => pdfField.getName() === fieldName);
        const widgets = pdfField?.acroField?.getWidgets();
        if (!widgets?.length) return undefined;

        const rect = widgets[0].getRectangle();
        return {
          width: rect.width,
          height: rect.height
        };
      })
      .filter(Predicate.isNotNull);
    const minCompositeSignatureDimensions = compositeSignatureDimensions.length
      ? {
        width: Math.min(...compositeSignatureDimensions.map(d => d.width)),
        height: Math.min(...compositeSignatureDimensions.map(d => d.height))
      }
      : undefined;

    const fieldDefinitionsMap = new Map(fieldDefinitions.map(fd => [fd.id, fd]));

    for (const field of pdfFields) {
      if (field instanceof PDFTextField) {
        await this.fillTextField(
          field,
          fieldDefinitionsMap,
          opts?.partyTimestamps || new Map<string, number>(),
          { minCompositeSignatureDimensions, requestFileFillNotFound }
        );
      } else if (field instanceof PDFRadioGroup) {
        await this.fillRadioField(
          field,
          fieldDefinitionsMap
        );
      } else if (field instanceof PDFCheckBox) {
        await this.fillCheckBoxField(field, fieldDefinitionsMap);
      } else {
        console.warn('unexpected form field type', field);
      }
    }

    if (opts?.noFlatten) return;

    selectiveFlatten(form, field => {
      const type = parseFieldName(field.getName())?.type;
      if (type !== FieldType.Serve) return true;

      // assumes the servedata has been applied
      return !!this.serveData;
    });
  }

  private async fillTextField(field: PDFTextField, fieldDefinitions: Map<string, FillFieldDefinition>, partyTimestamps: Map<string, number>, opts: { minCompositeSignatureDimensions?: Dimension, requestFileFillNotFound?: (file: FileRef) => void}) {
    const { minCompositeSignatureDimensions, requestFileFillNotFound } = opts ?? {};

    const fieldName = field.getName();
    const parsedFieldName = parseFieldName(fieldName);

    if (!parsedFieldName) {
      throw new Error(`could not parse field name ${fieldName} into a field id`);
    }

    switch (parsedFieldName.type) {
      case FieldType.FieldValue: {
        const matchingDefinition = fieldDefinitions.get(parsedFieldName.fieldId);
        if (!matchingDefinition) {
          if (this.allowPartial) return;
          throw new MissingFieldDefinitionError(parsedFieldName.fieldId);
        }
        await this.fillFieldValue(field, matchingDefinition, { minCompositeSignatureDimensions, requestFileFillNotFound });
        return;
      }
      case FieldType.FieldTimestamp: {
        const matchingDefinition = fieldDefinitions.get(parsedFieldName.fieldId);
        if (!matchingDefinition) {
          if (this.allowPartial) return;
          throw new MissingFieldDefinitionError(parsedFieldName.fieldId);
        }
        this.fillFieldTimestamp(field, matchingDefinition);
        return;
      }
      case FieldType.FieldWitness: {
        const matchingDefinition = fieldDefinitions.get(parsedFieldName.fieldId);
        if (!matchingDefinition) {
          if (this.allowPartial) return;
          throw new MissingFieldDefinitionError(parsedFieldName.fieldId);
        }
        this.fillFieldWitness(field, matchingDefinition);
        return;
      }
      case FieldType.System:
        this.fillSystemField(field, parsedFieldName.fieldId);
        return;
      case FieldType.Serve:
        this.fillServeField(field, parsedFieldName.fieldId);
        return;
      case FieldType.PartyTimestamp: {
        const timestamp = partyTimestamps.get(parsedFieldName.partyId);
        if (!timestamp) {
          if (this.allowPartial) return;
          throw new MissingPartyDetailsError(parsedFieldName.partyId, 'timestamp');
        }
        this.fillTimestamp(field, timestamp);
      }
    }
  }

  private async fillRadioField(field: PDFRadioGroup, fieldDefinitions: Map<string, FillFieldDefinition>) {
    for (const definition of fieldDefinitions.values()) {
      if (definition.type !== SigningSessionFieldType.Radio) continue;
      if (definition.text !== 'on') continue;
      const option = buildFieldName({ type: FieldType.FieldValue, fieldId: definition.id });
      if (!field.getOptions().includes(option)) continue;
      field.select(option);
    }
  }

  private async fillCheckBoxField(field: PDFCheckBox, fieldDefinitions: Map<string, FillFieldDefinition>) {
    const fieldName = field.getName();
    const parsedFieldName = parseFieldName(fieldName);

    if (parsedFieldName?.type !== FieldType.FieldValue) {
      throw new Error(`could not parse field name ${fieldName} into a field id`);
    }

    const matchingDefinition = fieldDefinitions.get(parsedFieldName.fieldId);
    if (!matchingDefinition) {
      if (this.allowPartial) return;
      throw new MissingFieldDefinitionError(parsedFieldName.fieldId);
    }

    if (matchingDefinition.text === 'on') {
      field.check();
    } else {
      field.uncheck();
    }
  }

  private async fillFieldValue(
    field: PDFTextField,
    fieldDefinition: FillFieldDefinition,
    opts?: {minCompositeSignatureDimensions?: Dimension, requestFileFillNotFound?: (file: FileRef) => void}
  ) {
    // future: behave differently if it's not an image...

    const { minCompositeSignatureDimensions, requestFileFillNotFound } = opts ?? {};
    if (fieldDefinition.isWetSigned) {
      //make the 'Signed on paper' text the same size as the date - have to move the form field down so it lines up
      const formField = field.acroField.getWidgets()?.[0];
      const rect = formField.getRectangle();
      formField.setRectangle({ ...rect, y: rect.y -12 });
      field.setFontSize(11);
      field.setText(fieldDefinition.text);
      return;
    }

    switch (fieldDefinition.type) {
      case SigningSessionFieldType.Text:
        field.setText(fieldDefinition.text);
        return;
      case SigningSessionFieldType.Initials:
      case SigningSessionFieldType.Signature:
        switch (fieldDefinition.subtype) {
          case SigningSessionSubType.RenderInfoInline: {
            if (!fieldDefinition.file) throw new Error('File ref missing - cannot fill field');
            const pdfImage = await this.loadPdfImageFromFileRef(fieldDefinition.file, async file => {
              const signature = await loadImageFromBlob(file);
              const canvas = createCanvas(300, 150);
              const ctx = canvas.getContext('2d');
              drawCompoundSignatureWithinDimensions({
                context: (ctx as any),
                signature: (signature as any),
                name: fieldDefinition.name || '',
                timestamp: fieldDefinition.timestamp <= 0
                  ? 'Signature upload pending'
                  : formatTimestamp(fieldDefinition.timestamp, this.timeZone, false),
                dimensions: minCompositeSignatureDimensions || field.acroField.getWidgets()[0].getRectangle(),
                textPixelsPerInch: 72
              });
              return ctx.canvas.toDataURL();
            });
            field.setImage(pdfImage);
            return;
          }
          default: {
            if (!fieldDefinition.file) throw new Error('File ref missing - cannot fill field');
            const pdfImage = await this.loadPdfImageFromFileRef(
              fieldDefinition.file,
              data => data.arrayBuffer(),
              { requestFileFillNotFound }
            );
            field.setImage(pdfImage);
            return;
          }
        }
    }
  }

  private fillFieldTimestamp(field: PDFTextField, fieldDefinition: FillFieldDefinition) {
    if (!fieldDefinition) return;

    this.fillTimestamp(field, fieldDefinition.timestamp);
  }

  private fillTimestamp(field: PDFTextField, timestamp: number) {
    field.setText(timestamp <= 0
      ? 'Signature upload pending'
      : formatTimestamp(timestamp, this.timeZone, false));
  }

  private fillFieldWitness(field: PDFTextField, fieldDefinition: FillFieldDefinition) {
    if (!fieldDefinition) return;

    field.setText(fieldDefinition.isWetSigned || fieldDefinition.timestamp <= 0
      ? ''
      : 'N/A');
  }

  /**
   * Get the location rects of a PDFField.
   * A field can appear multiple times, so there may be more than one.
   * Page number doesnt work all the time as the /P is not mandatory
   */
  private getRectsFromField(field: PDFField) {
    const widgets = field.acroField.getWidgets();
    const doc = field.doc;

    if (doc) {
      return widgets.map(q => {
        const rect = q.getRectangle();
        const pageNumber = doc.getPages().findIndex(x => x.ref == q.P());
        return { ...rect, pageNumber };
      });
    } else {
      return widgets.map(q => ({ ...q.getRectangle(), pageNumber: undefined }));
    }
  }

  private fillSystemField(field: PDFTextField, fieldName: SystemField) {
    field.setText(this.getSystemFieldValue(fieldName));
    if (fieldName === 'SIGNING_STATUS') {
      field.enableMultiline();
    }
  }

  private fillServeField(field: PDFTextField, fieldName: ServeField) {
    if (!this.serveData) return;

    switch (fieldName) {
      case 'PURCHASER_NAME':
        field.setText(this.serveData.purchaser.name);
        return;
      case 'PURCHASER_ADDRESS':
        field.setText(this.serveData.purchaser.address);
        return;
      case 'CONTRACT_DATE':
        field.setText(this.serveData.purchaser.contractDate);
        return;
    }
  }

  private getSystemFieldValue(type: SystemField): string {
    const match = this.systemFieldCache.get(type);
    if (match) return match;

    const value = this.getSystemFieldValueInner(type);
    this.systemFieldCache.set(type, value);
    return value;
  }

  private getSystemFieldValueInner(type: SystemField): string {
    if (type === 'SIGNING_STATUS') {
      return this.signingContext
        ? this.signingContext.completedAtMs
          ? 'Controlled Fully Signed'
          : 'Controlled Pending Signatures'
        : 'Generated';
    }

    if (type === 'DATE_OF_FILL') {
      return this.signingContext?.completedAtMs
        ? `on ${formatTimestamp(this.signingContext.completedAtMs, this.timeZone, false)}`
        : `on ${formatTimestamp(Date.now(), this.timeZone, false)}`;
    }

    if (type === 'SIGNING_AND_DATE') {
      const signingStatus = this.signingContext
        ? this.signingContext.completedAtMs
          ? 'Controlled Fully Signed'
          : 'Controlled Pending Signatures'
        : 'Generated';

      return signingStatus + (this.signingContext?.completedAtMs
        ? ` on ${formatTimestamp(this.signingContext.completedAtMs, this.timeZone, false)}`
        : ` on ${formatTimestamp(Date.now(), this.timeZone, false)}`);
    }

    if (!this.propertyData) return '';
    if (!this.propertyMeta) return '';
    if (!this.signingContext) return '';

    switch (type) {
      case 'DATE_OF_AGREEMENT':
        // maybe: make a variant of friendlyDateFormatter that's tz aware
        return this.signingContext.completedAtMs
          ? formatTimestamp(this.signingContext.completedAtMs, this.timeZone, false)
          : '';
      case 'DATE_OF_EXPIRY': {
        if (!this.signingContext.completedAtMs) return '';

        const days = ((duration?: string | number) => {
          switch (typeof duration) {
            case 'number':
              return duration > 0
                ? duration
                : undefined;
            case 'string': {
              const canon = canonicalisers.days(duration);
              if (!canon.valid) return undefined;
              const parsed = parseInt2(canon.canonical);
              if (!parsed || isNaN(parsed)) return undefined;

              return parsed > 0
                ? parsed
                : undefined;
            }
            default:
              return undefined;
          }
        })(this.propertyData.agency?.duration);

        if (!days) return '';

        const executedDate = new Date(this.signingContext.completedAtMs);
        return formatTimestamp(executedDate.setDate(executedDate.getDate() + days), this.timeZone, false);
      }
      default:
        return '';
    }
  }

  private async getDoc() {
    if (this.doc) {
      return this.doc;
    }
    const data = await this.fileProvider.getFile(this.baseFileId);
    if (!data) {
      throw new FileLoadError(this.baseFileId, ContentType.Pdf);
    }
    this.doc = await PDFDocument.load(data);
    return this.doc;
  }

  private async embedImage(file: FileRef, content: PdfEmbeddableImage) {
    switch (file.contentType) {
      case ContentType.Png:
        return (await this.getDoc()).embedPng(content);
      case ContentType.Jpeg:
        return (await this.getDoc()).embedJpg(content);
      default:
        throw new Error(`Cannot pdf-embed unrecognised content type ${file.contentType}`);
    }
  }

  private async loadPdfImageFromFileRef(fileRef: FileRef, transformer: (file: Blob) => Promise<PdfEmbeddableImage>, opts?: {requestFileFillNotFound?: (file: FileRef) => void}) {
    return await backOff(async () => {
      const existing = this.imageCache.get(fileRef.id);
      if (existing) {
        return existing;
      }

      const file = await this.fileProvider.getBlob(fileRef.id);
      if (!file) {
        opts?.requestFileFillNotFound?.(fileRef);
        throw new FileLoadError(fileRef.id, fileRef.contentType);
      }

      const embedded = await this.embedImage(fileRef, await transformer(file));
      this.imageCache.set(fileRef.id, embedded);
      return embedded;
    }, {
      retry: (attempt) => {
        console.warn(`[#${attempt}] failed to get file`, fileRef);
        return true;
      }
    });
  }
}

