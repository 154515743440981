import { EditFieldInfo, EditorType, BuildChangeSetFn, StringifyFn, EditorInfo, SubscriptionFormCode } from './types';
import { dataMappingDefinitions } from './data-mapping-definitions';
import { Predicate } from '../predicate';

function mapFieldNameByExplicitFormFileNameDetails(targetName?: string, formCode?: SubscriptionFormCode): EditFieldInfo | undefined {
  if (!targetName) return undefined;
  if (!formCode) return undefined;

  return dataMappingDefinitions[formCode]?.fields[targetName];
}

function getFieldsWithEditor(editor: EditorType, formCode: SubscriptionFormCode | undefined) {
  if (!formCode) return [];
  const formDefinition = dataMappingDefinitions[formCode];
  if (!formDefinition) return [];
  const fields = formDefinition.fields;

  return Object.keys(fields).map(key => {
    const value = fields[key];
    return value && value.editor === editor
      ? { targetName: key, stringify: value.stringify }
      : undefined;
  }).filter(Predicate.isNotNull);
}

function buildChangeSetFn(targetName: string, formCode: SubscriptionFormCode | undefined, baseEditInfo: EditFieldInfo): BuildChangeSetFn {
  const targetStringifyMap = new Map<string, StringifyFn>;
  targetStringifyMap.set(targetName, baseEditInfo.stringify);

  const otherEditorTargets = getFieldsWithEditor(baseEditInfo.editor, formCode);
  for (const otherTarget of otherEditorTargets) {
    if (targetStringifyMap.has(otherTarget.targetName)) continue;

    targetStringifyMap.set(otherTarget.targetName, otherTarget.stringify);
  }

  const stringifyEntries = [...targetStringifyMap.entries()];
  return (data, meta) => {
    return stringifyEntries.map(([targetName, stringify]) => {
      return { targetName, value: stringify(data, meta) };
    });
  };
}

export function mapFieldName(targetName?: string, formCode?: SubscriptionFormCode): EditorInfo | undefined {
  if (!targetName) return undefined;

  const explicitResult = mapFieldNameByExplicitFormFileNameDetails(targetName, formCode);
  if (!explicitResult) {
    return undefined;
  }

  return {
    editor: explicitResult.editor,
    getChanges: buildChangeSetFn(targetName, formCode, explicitResult)
  };
}
