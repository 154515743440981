import { LinkBuilder } from '../util/LinkBuilder';
import { WrappedFetch } from './wrappedFetch';
import { Maybe } from '@property-folders/contract';
import { PutPropertyFileRefResult } from '@property-folders/contract/rest/property';

export class FileApi {
  public static async putFileRef(entityUuid: string, fileId: string, params: {
    contentType: string;
    transportContentType?: string,
    fileType: 'MARKETING';
    parentId: string
  }): Promise<Maybe<PutPropertyFileRefResult>> {
    const qs = LinkBuilder.buildQueryString(params);
    return WrappedFetch.json(LinkBuilder.restApi(`/entities/${entityUuid}/file-refs/${fileId}?${qs}`), {
      method: 'PUT'
    });
  }

  public static async getFile(entityUuid: string, fileId: string) {
    const result = await WrappedFetch.bare(LinkBuilder.restApi(`/entities/${entityUuid}/files/${fileId}`));
    return {
      data: await result.blob(),
      contentType: result.headers.get('content-type')
    };
  }
}
