import { PortionOfLandDwellingDetails } from '@property-folders/contract';

export class Dwelling {
  static isSimple(dwelling: PortionOfLandDwellingDetails) {
    return Boolean(dwelling.vacantLand && !dwelling.demolitionRequired && !dwelling.purchaserWillBuild);
  }

  static sectionVisibility(dwelling: PortionOfLandDwellingDetails | undefined) {
    const purchaserWillBuildQuestion = [true, undefined].includes(dwelling?.vacantLand);
    const purchaserBuildInfo = purchaserWillBuildQuestion && Boolean(dwelling?.purchaserWillBuild);
    const vendorWillBuildQuestion = [false, undefined].includes(dwelling?.vacantLand);
    const vendorBuildInfo = vendorWillBuildQuestion && Boolean(dwelling?.vendorWillBuild);

    return {
      purchaserWillBuildQuestion,
      purchaserBuildInfo,
      vendorWillBuildQuestion,
      vendorBuildInfo
    };
  }
}
