/**
 * Scales a canvas by a given factor
 * @param canvas
 * @param factor
 */
export function scaleCanvas(canvas: HTMLCanvasElement, factor: number) {
  const tempCanvas= document.createElement('canvas');
  const tempCtx= tempCanvas.getContext('2d');
  if (!tempCtx) return;

  const cw= canvas.width;
  const ch= canvas.height;
  tempCanvas.width = cw;
  tempCanvas.height = ch;
  tempCtx.imageSmoothingEnabled = true;
  tempCtx.imageSmoothingQuality = 'high';
  tempCtx.drawImage(canvas,0,0);
  canvas.width *= factor;
  canvas.height *= factor;
  const ctx= canvas.getContext('2d');
  ctx?.drawImage(tempCanvas,0,0,cw,ch,0,0,cw*factor,ch*factor);
}
