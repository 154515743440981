import {
  drawObject,
  PDFField,
  PDFForm,
  PDFOperator,
  PDFPage,
  PDFRef,
  popGraphicsState,
  pushGraphicsState,
  rotateInPlace,
  translate
} from 'pdf-lib';
import { FlattenOptions } from 'pdf-lib/es/api/form/PDFForm';

/**
 * Refer https://github.com/Hopding/pdf-lib/blob/master/src/api/form/PDFForm.ts#L537-L569
 */
export function selectiveFlatten(form: PDFForm, predicate: (field: PDFField) => boolean, options: FlattenOptions = { updateFieldAppearances: true }) {
  if (options.updateFieldAppearances) {
    form.updateFieldAppearances();
  }

  const fields = form.getFields();

  for (let i = 0, lenFields = fields.length; i < lenFields; i++) {
    const field = fields[i];
    if (!predicate(field)) continue;
    const widgets = field.acroField.getWidgets();

    for (let j = 0, lenWidgets = widgets.length; j < lenWidgets; j++) {
      const widget = widgets[j];
      // @ts-ignore
      const page = form.findWidgetPage(widget);
      // @ts-ignore
      const widgetRef = form.findWidgetAppearanceRef(field, widget);

      const xObjectKey = page.node.newXObject('FlatWidget', widgetRef);

      const rectangle = widget.getRectangle();
      const operators = [
        pushGraphicsState(),
        translate(rectangle.x, rectangle.y),
        ...rotateInPlace({ ...rectangle, rotation: 0 }),
        drawObject(xObjectKey),
        popGraphicsState()
      ].filter(Boolean) as PDFOperator[];

      page.pushOperators(...operators);
    }

    removeFormField(form, field);
  }
}

/**
 * This is a copy of the pdf-lib function from this PR: https://github.com/Hopding/pdf-lib/pull/1268
 * To fix the original version not cleaning up annotations correctly
 */
function removeFormField(form: PDFForm, field: PDFField) {
  const widgets = field.acroField.getWidgets();
  const pages: Set<PDFPage> = new Set();

  for (let i = 0, len = widgets.length; i < len; i++) {
    const widget = widgets[i];
    const widgetRef = field.doc.context.getObjectRef(widget.dict);

    // @ts-ignore
    const page = form.findWidgetPage(widget);
    pages.add(page);
    if (widgetRef !== undefined) {
      page.node.removeAnnot(widgetRef);
    }
  }

  pages.forEach((page) => page.node.removeAnnot(field.ref));
  form.acroForm.removeField(field.acroField);
  const fieldKids = field.acroField.normalizedEntries().Kids;
  const kidsCount = fieldKids.size();
  for (let childIndex = 0; childIndex < kidsCount; childIndex++) {
    const child = fieldKids.get(childIndex);
    if (child instanceof PDFRef) {
      field.doc.context.delete(child);
    }
  }
  field.doc.context.delete(field.ref);
}
