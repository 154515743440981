import { useInfiniteQuery } from '@tanstack/react-query';
import { cloneSimple } from '../util/cloneSimple';
import { LinkBuilder } from '../util/LinkBuilder';
import { WrappedFetch } from './wrappedFetch';

import type { ListFoldersForAgent, ListFoldersQuery, FolderDetailsPathParams, FolderDetails } from '@property-folders/contract/rest/folders';

export class FoldersApi {
  static async listFoldersForAgent(params: ListFoldersQuery) {
    return {
      ...await WrappedFetch.jsonWithDate<ListFoldersForAgent>(LinkBuilder.restApi(`/folders?${LinkBuilder.buildQueryString(params)}`))
    };
  }

  static useInfiniteFoldersList(defaultParams: ListFoldersQuery) {
    return useInfiniteQuery(
      [
        'folders',
        'list',
        defaultParams.limit ?? 100,
        defaultParams.searchTerm ?? ''
      ],
      async context => {
        return await FoldersApi.listFoldersForAgent(context.pageParam ?? defaultParams);
      },
      {
        getNextPageParam: (lastPage, _) => {
          if (!lastPage?.results || lastPage.results.length === 0) {
            return undefined;
          }

          const p = cloneSimple(defaultParams);
          p.pageNumber = (lastPage.pageNumber ?? 1) + 1;
          return p;
        }
      }
    );
  }

  static async getDetails({ folderId }: FolderDetailsPathParams) {
    const apiResult = await WrappedFetch.json<FolderDetails>(LinkBuilder.restApi(`/folders/${folderId}`));

    return {
      results: apiResult?.results ?? null
    };
  }
}
