import { EntityBrandPurchaserRegistrationConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { transparentPixel } from '@property-folders/common/util/pdfgen/logos';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { A4_HEIGHT, A4_WIDTH } from '../../measurements';
import { prettifyPhone } from '../../../formatting';

export const getPurchaserSelfRegistrationDefinition = (property: MaterialisedPropertyData, purchaserHeaderImage: string | undefined, brand: EntityBrandPurchaserRegistrationConfig, qrCodeUrl: string) => {
  const prop = property.saleAddrs?.[0];
  const agent = property.agent?.[0]?.salesp?.[0];
  const MARGIN_X = 0;
  const MARGIN_Y = 0;
  const IMAGE_HEIGHT = 170;
  const IMAGE_PADDING = 20;

  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: purchaserHeaderImage || transparentPixel
    },
    pageMargins: [MARGIN_X,MARGIN_Y,MARGIN_X,MARGIN_Y],
    background: () =>  ({
      canvas: [
        {
          type: 'rect',
          x: 0, y: 0, w: A4_WIDTH, h: A4_HEIGHT,
          color: brand.page?.backgroundColour || '#FFF'
        }
      ]
    }),
    content: [
      {
        image: 'agencyLogo',
        width: A4_WIDTH - MARGIN_X - MARGIN_X,
        height: IMAGE_HEIGHT,
        margin: [0, 0, 0, 0]
      },
      {
        stack: [
          {
            text: agent?.name || '',
            fontSize: 30,
            color: brand.page?.foregroundColour,
            alignment: 'right'
          },
          {
            text: agent?.email || '',
            fontSize: 20,
            color: brand.page?.foregroundColour,
            alignment: 'right',
            margin: [0,0,0,2]
          },
          {
            text: prettifyPhone(agent?.phone, false, undefined, false) || '',
            fontSize: 19,
            color: brand.page?.foregroundColour,
            alignment: 'right'
          }
        ],
        relativePosition: { x: -IMAGE_PADDING, y: -IMAGE_HEIGHT + (IMAGE_HEIGHT/4) }
      },
      {
        canvas: [{
          type: 'rect', x: 0, y: 0, w: A4_WIDTH - MARGIN_X - MARGIN_X, h: 200,
          color: brand.stripe?.backgroundColour || '#000'
        }],
        margin: [0,50,0,0]
      },
      {
        stack: [
          { text: prop?.streetAddr, fontSize: 45, color: brand.stripe?.foregroundColour || '#DDD' },
          { text: prop?.subStateAndPost, fontSize: 25, color: brand.stripe?.foregroundColour || '#DDD' }
        ],
        margin: [30,0,0,0],
        relativePosition: { y: -140 }
      },
      {
        columns: [
          {
            width: 320,
            stack: [
              { text: 'Documentation', fontSize: 30, bold: true, color: brand.page?.foregroundColour },
              { text: 'Please scan the code with your smartphone camera:', fontSize: 25, color: brand.page?.foregroundColour, margin: [0,5,0,0] }
            ]
          }
        ],
        margin: [30,170,0,0]
      },
      {
        image: qrCodeUrl,
        width: 200,
        height: 200,
        margin: [A4_WIDTH-230, -150, 0, 0]
      }
    ]
  });
};
