import { ExtraFormCode, FormCode, FormCodeUnion } from '@property-folders/contract/property/index';
import { SubscriptionFormCode } from './types';

export interface FormRefPair {
  formFileName: string;
  formCode: FormCodeUnion
}
const pairs: FormRefPair[] = [
  { formCode: FormCode.RSAA_SalesAgencyAgreement, formFileName: 'SAR012' },
  { formCode: FormCode.RSC_ContractOfSale, formFileName: 'SAR005' },
  { formCode: FormCode.Form1, formFileName: 'SAF001V2' },
  { formCode: FormCode.LicenceToOccupy, formFileName: 'SACS015' },
  { formCode: FormCode.OfferToPurchase, formFileName: 'SAR014' },
  { formCode: FormCode.VendorQuestionnaire, formFileName: 'SAR008' },
  { formCode: ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent, formFileName: 'SAR013' },
  { formCode: ExtraFormCode.AAV_SalesAgencyAgreementVariation, formFileName: 'SAR001' },
  { formCode: ExtraFormCode.SCT_ContractOfSaleTermination, formFileName: 'SACS014' },
  { formCode: SubscriptionFormCode.SAF001V2_Form1, formFileName: 'SAF001V2' },
  { formCode: SubscriptionFormCode.SACS015_LicenceToOccupy, formFileName: 'SACS015' },
  { formCode: SubscriptionFormCode.SAR014_NoticeOfOfferToPurchaseResidentialLand, formFileName: 'SAR014' },
  { formCode: SubscriptionFormCode.SAR008_VendorQuestionnaire, formFileName: 'SAR008' }
];
export const formCodeToFileNameMap = new Map(pairs.map(p => ([p.formCode, p.formFileName])));
export const fileNameToFormCodeMap = new Map(pairs.map(p => ([p.formFileName, p.formCode])));
