import { EditHtmlDefinition } from './types';
import { SubscriptionFormCode } from './index';
import { PartyCategory } from '../yjs-schema/property/form';

export const DataSetKey = {
  subForms: 'subForms',
  subFormsAttribute: 'data-sub-forms',
  subFormsDelete: 'subFormsDelete',
  subFormsDeleteAttribute: 'data-sub-forms-delete',
  subFormsSignatureParty: 'subFormsSignatureParty',
  subFormsSignaturePartyAttribute: 'data-sub-forms-signature-party',
  subFormsSignatureSpace: 'subFormsSignatureSpace',
  subFormsSignatureSpaceAttribute: 'data-sub-forms-signature-space'
};

function elementTextIs(value: string) {
  const canonical = value.toLowerCase();
  return (element: Element) => {
    return (element.textContent || '').toLowerCase() === canonical;
  };
}

function elementTextContainsAny(values: string[]) {
  const canonical = values.map(s => s.toLowerCase());
  return (element: Element) => {
    const textContent = (element.textContent || '').toLowerCase();
    return canonical.some(value => textContent.indexOf(value) >= 0);
  };
}

function descendentMeetsCriteria(node: Element, selector: string, criteria: (child: Element) => boolean) {
  return [...node.querySelectorAll(selector)]
    .some(criteria);
}

function xpathQuery(document: Document, expression: string) {
  const iterator = document.evaluate(
    expression,
    document,
    null,
    XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
    null);

  const nodes: Element[] = [];
  let node = iterator.iterateNext();
  while (node) {
    nodes.push(node as Element);
    node = iterator.iterateNext();
  }
  return nodes;
}

function domQuery(document: Document, cssSelector: string, xpathSelector?: string) {
  try {
    return [...document.querySelectorAll(cssSelector)];
  } catch (err: unknown) {
    if (xpathSelector) {
      return xpathQuery(document, xpathSelector);
    }
    console.warn(err);
    return [];
  }
}

/**
 * If the candidate does not have any ancestors which are also candidates, then mark it as the signature injection point
 * @param candidate
 * @param allCandidates
 */
function markSignatureInjectionPointIfHighestAncestor(candidate: Element, allCandidates: Set<Element>, party: PartyCategory) {
  try {
    let parent = (candidate as HTMLElement).parentElement;
    while (parent) {
      if (allCandidates.has(parent)) return;
      parent = parent.parentElement;
    }
    (candidate as HTMLElement).dataset[DataSetKey.subFormsSignatureParty] = party;
  } catch (err: unknown) {
    console.error(err);
  }
}

function injectSignatures(document: Document, partyElementPairs: [Element[], PartyCategory][], debug: boolean | undefined) {
  // mark potential injection points
  for (const [elements, party] of partyElementPairs) {
    new Set(elements)
      .forEach((element, index, all) => {
        markSignatureInjectionPointIfHighestAncestor(element, all, party);
      });
  }

  const distinctPartyCategories = new Set(partyElementPairs.map(([_, cat]) => cat));
  for (const party of distinctPartyCategories) {
    const injectAfter = [...document.querySelectorAll(`[${DataSetKey.subFormsSignaturePartyAttribute}="${party}"]`)].at(-1);
    if (!injectAfter) continue;

    // during dev, avoids HMR triggering a re-run and creating multiple signature spaces of the same type
    if ([...document.querySelectorAll(`[${DataSetKey.subFormsSignatureSpaceAttribute}="${party}"]`)].length) {
      continue;
    }

    if (debug) {
      console.log(`inject ${party} after`, injectAfter);
    }
    const tr = document.createElement('tr');
    tr.dataset[DataSetKey.subForms] = 'true';
    const signatureSpace = document.createElement('td');
    signatureSpace.dataset[DataSetKey.subForms] = 'true';
    signatureSpace.dataset[DataSetKey.subFormsSignatureSpace] = party;
    tr.append(signatureSpace);
    injectAfter.after(tr);
  }
}

function markForDeletion(element: Element) {
  try {
    // if the process re-runs, don't snot elements we've inserted and are managing
    if (DataSetKey.subForms in (element as HTMLElement).dataset) {
      return;
    }
    (element as HTMLElement).dataset[DataSetKey.subFormsDelete] = 'true';
  } catch (err: unknown) {
    console.error(err);
  }
}

function deleteElement(debug?: boolean) {
  return (element: Element) => {
    try {
      if (debug) {
        (element as HTMLElement).style.border = '2px dashed red';
        return;
      }

      // element.remove();
      // or
      (element as HTMLElement).style.display = 'none';
    } catch (err: unknown) {
      console.error(err);
    }
  };
}

function deleteMarkedElements(document: Document, debug: boolean | undefined) {
  domQuery(document, `[${DataSetKey.subFormsDeleteAttribute}]`)
    .forEach(deleteElement(debug));
}

export const editHtmlDefinitions: Record<SubscriptionFormCode, EditHtmlDefinition | undefined> = {
  [SubscriptionFormCode.SACS015_LicenceToOccupy]: {
    editFns: [
      (document, debug) => {
        domQuery(document, 'tr.vendor1 ~ tr')
          .forEach(markForDeletion);

        injectSignatures(document, [
          [domQuery(document, 'tr.vendor1'), 'vendor'],
          [domQuery(document, 'tr.purchaser1'), 'purchaser']
        ], debug);

        deleteMarkedElements(document, debug);
      }
    ]
  },
  [SubscriptionFormCode.SAR008_VendorQuestionnaire]: {
    editFns: [
      (document, debug) => {
        domQuery(document,
          'tr:has(td.Signature)',
          '//tr[descendant::td[contains(@class,"Signature")]]'
        )
          .forEach(markForDeletion);
        domQuery(document, '.EndUserFields')
          .forEach(el => el.classList.remove('EndUserFields'));

        injectSignatures(document,[
          [domQuery(document, 'tr:has(#Signature_VENDOR)', '//tr[descendant::*[@id="Signature_VENDOR"]]'), 'vendor']
        ], debug);

        deleteMarkedElements(document, debug);
      }
    ]
  },
  [SubscriptionFormCode.SAR014_NoticeOfOfferToPurchaseResidentialLand]: undefined,
  [SubscriptionFormCode.SAF001V2_Form1]: {
    editFns: [
      (document, debug) => {
        // remove practice note rows related to signing
        domQuery(document,
          'tr:has(>td>div.card strong)',
          '//tr[child::td[child::div[contains(@class,"card") and descendant::strong]]]'
        )
          .filter(candidate =>
            descendentMeetsCriteria(candidate, 'strong', elementTextIs('Practice Note')) &&
              descendentMeetsCriteria(candidate, 'p', elementTextContainsAny([
                'This Form 1 can be duplicated as an original signed Form 1',
                'The Vendor will get a link to download and read the Form 1 prior to execution on signing.',
                'The Agent can just click and sign below if preparing the Form 1',
                'This signature will appear on the last page'
              ])))
          .forEach(markForDeletion);
        domQuery(document,
          'tr.pad:has(td.small,p.small)',
          '//tr[contains(@class,"pad") and descendant::td[contains(@class,"small")]|descendant::p[contains(@class,"small")]]'
        )
          .filter(candidate =>
            descendentMeetsCriteria(candidate, 'td.small', elementTextContainsAny([
              'this will be auto populated if purchaser signs'
            ])) ||
            descendentMeetsCriteria(candidate, 'p.small', elementTextContainsAny([
              'The Purchaser acknowledges and consents to the parties and their',
              'The Purchaser signing this Acknowledgement signs for all Purchasers'
            ])))
          .forEach(markForDeletion);
        domQuery(document,
          'tr:has(td.Signature)',
          '//tr[descendant::td[contains(@class,"Signature")]]'
        )
          .forEach(markForDeletion);

        injectSignatures(document, [
          [domQuery(document, 'tr:has(#Signature_AGENT)', '//tr[descendant::*[@id="Signature_AGENT"]]'), 'agent'],
          [domQuery(document, 'tr:has(#Signature_VENDOR)', '//tr[descendant::*[@id="Signature_VENDOR"]]'), 'vendor'],
          [domQuery(document, 'tr:has(#Signature_VENDOR2)', '//tr[descendant::*[@id="Signature_VENDOR2"]]'), 'vendor']
        ], debug);

        deleteMarkedElements(document, debug);
      }
    ]
  }
};
