import { PropertyFormYjsDal } from '../yjs-schema/property/form';
import { FileRef, IFileProvider } from '@property-folders/contract';
import { FillFormServeData, PdfFormFiller } from './pdf-form-filler';
import { FileLoadError } from './errors';

export async function fillPdf(
  formDal: PropertyFormYjsDal,
  fileProvider: IFileProvider,
  formId: string,
  formCode: string,
  signingSessionId: string,
  timeZone: string,
  allowPartial: boolean,
  completed: boolean,
  optionals?: {
    serveData?: FillFormServeData,
    allowUsingCompletedFile?: boolean,
    noFlatten?: boolean,
    requestFileFillNotFound?: (file: FileRef) => void
  }

) {
  const { allowUsingCompletedFile, noFlatten, requestFileFillNotFound, serveData } = optionals??{};
  const form = formDal.getFormInstance(formCode, formId);
  const partyTimestamps = new Map<string, number>((form?.signing?.parties || [])
    .map(p => [
      p.id,
      p.signedTimestamp
        ? p.serverAcceptPending
          ? -1
          : p.signedTimestamp
        : 0
    ]));
  const stitchingData = formDal.getDataForPdfStitching(formCode, formId, signingSessionId, partyTimestamps, completed);
  if (!stitchingData) {
    console.warn('pdf has no fields. Applicable to PDF preview generation');
    return undefined;
  }

  let file = stitchingData.basePdf;

  if (allowUsingCompletedFile) {
    if (form?.signing?.session?.completedFile) {
      if (Array.isArray(form.signing.session.completedFile)) {
        if (form.signing.session.completedFile.length > 0) {
          file = form.signing.session.completedFile[0];
        }
      } else {
        file = form.signing.session.completedFile;
      }
    }
  }

  const filler = new PdfFormFiller(
    file.id,
    fileProvider,
    timeZone,
    allowPartial,
    undefined,
    undefined,
    {},
    serveData
  );
  try {
    await filler.fill(stitchingData.fields, { partyTimestamps, noFlatten, requestFileFillNotFound });
  } catch (e) {
    if (e instanceof FileLoadError) {
      console.warn('File could not be loaded for filling');
    }
    return;
  }

  return await filler.getBytes({
    titleTransformer: completed
      ? original => original ? `Fully executed ${original}` : 'Fully executed'
      : undefined
  });
}
