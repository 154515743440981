import { HumanizedValueToken } from './index';
import { ChangeSet } from '@property-folders/common/util/object';

export enum EnvelopeDirection {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION'
}

export enum EnvelopeStatus {
  PENDING = 'PENDING',
  CLAIMED = 'CLAIMED',
  PROCESSED = 'PROCESSED'
}

export interface PropertyEnvelope {
  envelopeId: string;
  sourceEntityUuid: string;
  sourcePropertyFolderUuid: string;
  destinationEntityUuid: string;
  destinationPropertyFolderUuid?: string;
  status: EnvelopeStatus;
  createdTimestampMs: number;
  metadata: Record<string, string>;
  headline: string;
  payloadDiff: ChangeSet
}

export interface GetEnvelopesForProperty {
  Results: PropertyEnvelope[];
}

export interface PropertyFromEnvelopeResponse {
  propertyFolderId: string;
  sourceEntityName: string;
  documents: Record<string, string>
}

export type PropertyEnvelopeMergeResponse = void;

export interface PropertyEnvelopeGetResponse {
  Results: {
    envelopeId: string;
    status: EnvelopeStatus;
    sourcePropertyFolderUuid: string;
    sourceEntityUuid: string;
    destinationPropertyFolderUuid: string | null;
    destinationEntityUuid: string;
    createdTimestampMs: number;
    metadata: {[key: string]: string | number};
    headline: string;
    payloadDiff: ChangeSet
  }[];
}

export interface GetEnvelopeChangesResponse {
  metadata: Record<string, any>;
  position: string;
  timestampMs: number;
  agentName: string;
  changes: {
    rawPath: string;
    path: string;
    type: 'created' | 'updated' | 'deleted' | 'unchanged',
    from: any;
    to: any;
    desc: HumanizedValueToken
  }[]
}
