import { InlinePlanParcel, LssaIdentifier } from '../client-api/LssaCommon';

export class Lssa {
  public static parcelIdentifierToParts(id: LssaIdentifier) {
    const idParts = id.resourceName.split(':');
    const displayNameParts = id.displayName.split(' ');

    return {
      lot: displayNameParts[1],
      lotid: displayNameParts[2],
      plan: this.expandPlanTypeAbbreviation(idParts[3]),
      planid: idParts[4]
    };
  }

  static ParcelToParts(parcel: InlinePlanParcel) {
    return {
      lot: this.expandParcelTypeAbbreviation(parcel.parcelType),
      lotid: parcel.parcelNumber,
      plan: this.expandPlanTypeAbbreviation(parcel.planType),
      planid: parcel.planNumber
    };
  }

  private static expandParcelTypeAbbreviation(char: string) {
    char = char.toLowerCase();
    switch (char) {
      case 'al':
        return 'Allotment';
      case 'bl':
        return 'Block';
      case 'fl':
        return 'Lot';
      case 'fc':
        return 'Common Property (Community Plan)';
      case 'uc':
        return 'Common Property (Strata Plan)';
      case 'ac':
        return 'Closed Road Marked';
      case 'id':
        return 'Development Lot';
      case 'qp':
        return 'Piece';
      case 'ar':
        return 'Allotment (Road)';
      case 'ae':
        return 'Allotment (Reserve)';
      case 'se':
        return 'Section';
      case 'ta':
        return 'Town Acre';
      case 'un':
        return 'Unit';
      case 'at':
        return 'Allotment (Thoroughfare)';
      default:
        return char;
    }
  }

  private static expandPlanTypeAbbreviation(char: string) {
    char = char.toLowerCase();
    switch (char) {
      case 'c':
        return 'Community Plan';
      case 'fb':
        return 'Crown Survey Field Book';
      case 'dr':
        return 'DR Docket Plan';
      case 'd':
        return 'Deposited Plan';
      case 'u':
        return 'Drawer Plan';
      case 'f':
        return 'Filed Plan';
      case 'g':
        return 'GRO Plan';
      case 'la':
        return 'LTO A Plan';
      case 'lb':
        return 'LTO B Plan';
      case 'lc':
        return 'LTO C Plan';
      case 'm':
        return 'Miscellaneous Survey Record';
      case 'a':
        return 'PM Tracing';
      case 'parl':
        return 'Parliamentary Plan';
      case 'pfb':
        return 'Pastoral Field Book';
      case 'plot':
        return 'Plot';
      case 'j':
        return 'Rack Plan';
      case 'r':
        return 'Road Plan';
      case 'sd':
        return 'SD Docket Plan';
      case 'pm':
        return 'SM Locality Plan';
      case 's':
        return 'Strata Plan';
      case 'sgi':
        return 'Survey Graphic Index';
      case 'b':
        return 'Tube Plan';
      case 't': // note this isn't in the official documentation.
        return 'Town Plan';
      case 'h':
        // https://landservices.com.au/support-materials-and-resources/glossary-of-property-terms
        // can be either "Hundred Plan" or "Out of Hundred Plan", best to leave it ambiguous
        return 'H';
      default:
        return char.toUpperCase();
    }
  }
}
