import * as creditCardType from './cc-type';

import { cardholderName } from './cardholder-name';
import { cardNumber as number } from './card-number';
import { expirationDate } from './expiration-date';
import { expirationMonth } from './expiration-month';
import { expirationYear } from './expiration-year';
import { cvv } from './cvv';
import { postalCode } from './postal-code';

export const cardValidator = {
  creditCardType,
  cardholderName,
  number,
  expirationDate,
  expirationMonth,
  expirationYear,
  cvv,
  postalCode
};

