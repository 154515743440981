import { cleanupUser } from './user-cleanup-common';
import { OfflineSession } from '../offline/offlineSession';

export async function completeLogoutPhp() {
  const response = await (await fetch('/legacy/ajax.php?action=logout', {
    method: 'POST'
  })).json();
  if (response.success) {
    window.location.href = '/login';
  }
  else {
    console.error('Failed to logout');
  }
}

export async function uncheckedLogout(agentId: number, agentUuid: string | undefined) {
  if (!agentId) {
    return completeLogoutPhp();
  }

  try {
    await cleanupUser(agentId, agentUuid, 'unsynced');
  } catch (err: unknown) {
    console.error('error while clearing user data', err);
  } finally {
    completeLogoutPhp();
  }
}

export async function getCurrentAgentId() {
  return (await OfflineSession.read())?.agentId || 0;
}
