export enum OfferState {
  Unknown = 'unknown',
  Submitted = 'submitted',
  Incomplete = 'incomplete',
  Signed = 'signed',
  Draft = 'draft',
  Terminated = 'terminated',
  Declined = 'declined',
  Withdrawn = 'withdrawn'
}

export enum OfferType {
  LetterOfOffer = 1,
  OfferContract = 2
}

export type OfferValues = {
  offerPrice?: number
  deposit?: number
  settlementDate?: string
  conditions?: {
    finance?: boolean
    salePurchaserProperty?: boolean
  }
  headline?: string // Used to generate link URL
};

export type PartySignedSummary = {
  name: string,
  signedDate?: Date // These will have to be the latest signing date if a party has multiple signers
};

export type OfferContractSummary = {
  prospectivePurchaserId: string
  lineageRootKey: string
  state: OfferState,
  stateTime: Date | null,
  purchasers?: PartySignedSummary[]
  vendors?: PartySignedSummary[]
  label?: string
  archived?: boolean
  form1Served?: string
} & OfferValues;
