import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import type { ListCategoriesForAgent, ListFormsForAgent } from '@property-folders/contract/rest/forms';
import type { ModuleIdFromForm, ModuleIdFromFormPathParams, ModulesFromForm, ModulesFromFormPathParams } from '@property-folders/contract/rest/forms';

export class FormsApi {
  static async listFormsForAgent() {
    const apiResult = await WrappedFetch.json<ListFormsForAgent>(LinkBuilder.restApi('/forms'));

    return {
      results: apiResult?.results ?? []
    };
  }

  static async listCategoriesForAgent() {
    const apiResult = await WrappedFetch.json<ListCategoriesForAgent>(LinkBuilder.restApi('/forms/categories'));

    return {
      hasMultipleProducts: apiResult?.hasMultipleProducts ?? false,
      results: apiResult?.results ?? []
    };
  }

  static async getModuleIdFromForm({ formId }: ModuleIdFromFormPathParams) {
    const apiResult = await WrappedFetch.json<ModuleIdFromForm>(LinkBuilder.restApi(`/forms/${formId}/moduleId`));

    return {
      results: apiResult?.results ?? null
    };
  }

  static async getModulesFromForm({ formId }: ModulesFromFormPathParams) {
    const apiResult = await WrappedFetch.json<ModulesFromForm>(LinkBuilder.restApi(`/forms/${formId}/modules`));

    return {
      results: apiResult?.results ?? null
    };
  }
}
