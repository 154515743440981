import { ContractTerminationDepositReturn } from '../../property';
import { merge } from 'lodash';
import { globalFieldGroups } from '../field';
import { ValidationDefnType } from '../validation-field';
import { purchaserChildRules, vendorChildRules } from './common-rules';

export const saleContractTerminationFormRules: ValidationDefnType = {
  contractTermination: {
    depositOption: { _required: false },
    splitVendorReleaseAmt: { _requiredIf: {
      expectedValue: ContractTerminationDepositReturn.Split,
      field: ['..', 'depositOption']
    } },
    splitPurchaserReturnAmt: { _requiredIf: {
      expectedValue: ContractTerminationDepositReturn.Split,
      field: ['..', 'depositOption']
    } },
    otherTerms: { _requiredIf: {
      expectedValue: ContractTerminationDepositReturn.Other,
      field: ['..', 'depositOption']
    } }
  },

  primaryVendor: {
    _required: false
  },
  primaryPurchaser: {
    _required: false
  },
  saleAddrs: {
    _children: {
      _required: false,
      _readOnly: true,
      hundred: {
        _required: false,
        _readOnly: true
      },
      irrigationArea: {
        _required: false,
        _readOnly: true
      },
      lga: {
        _required: false,
        _readOnly: true
      },
      singleLine: {
        _readOnly: true
      },
      streetAddr: {
        _readOnly: true
      },
      subStateAndPost: {
        _readOnly: true
      },
      titles: {
        _children: {},
        _minimum: 0,
        _readOnly: true
      }
    },
    _minimum: 0,
    _required: false,
    _readOnly: true
  },
  saleTitles: {
    _children: {
      _required: false,
      _readOnly: true,
      isWhole: {
        _readOnly: true
      },
      title: {
        _readOnly: true
      },
      subTitles: {
        _children: {
          portionType: {
            _readOnly: true
          }
        },
        _readOnly: true

      }
    },
    _required: false,
    _readOnly: true
  },
  titleDivision: {
    _required: false,
    _readOnly: true,
    depositChoice: {
      _requiredIf: { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
      _readOnly: true
    },
    proposedLots: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] }
      ], modeOrTrueAndFalse: false },
      _readOnly: true
    },
    plan: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] }
      ], modeOrTrueAndFalse: false },
      _readOnly: true
    }
  },
  vendors: {
    _readOnly: true,
    _children: merge(structuredClone(vendorChildRules),{
      _required: false,
      _readOnly: true,
      abn: {
        _required: false,
        _readOnly: true
      },
      addrSameAsSale: {
        _required: false,
        _readOnly: false
      },
      authority: {
        _readOnly: true
      },
      email1: {
        _required: false
      },
      fullLegalName: {
        _readOnly: true
      },
      inTrust: {
        _required: false,
        _readOnly: true
      },
      partyType: {
        _readOnly: true
      },
      phone1: {
        _required: false
      },
      primarySubcontact: {
        _required: false
      },
      registeredOnTitle: {
        _required: false,
        _readOnly: true
      },
      addressSingleLine: {
        _required: false,
        _readOnly: false
      },
      mortgageNumber: {
        _required: false
      }
    }),
    _minimum: 1,
    _required: false
  },
  purchasers: {
    _children: merge(structuredClone(purchaserChildRules),{
      _required: false,

      abn: {
        _required: false,
        _readOnly: true
      },
      addressSingleLine: {
        _required: false,
        _readOnly: false
      },
      addrSameAsPrimary: {
        _required: false,
        _readOnly: false
      },
      authority: {
        _readOnly: true
      },
      email1: {
        _required: false,
        _readOnly: false
      },
      fullLegalName: {
        _readOnly: true
      },
      inTrust: {
        _required: false,
        _readOnly: true
      },
      partyType: {
        _readOnly: true
      },
      phone1: {
        _required: false,
        _readOnly: false
      },
      primarySubcontact: {
        _required: false,
        _readOnly: true
      }

    }),
    _required: false,
    _minimum: 1,
    _readOnly: true // May not be implemented for collections yet
  }
};

export const fieldGroups = {
  ...globalFieldGroups
};
