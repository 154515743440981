import { WrappedFetch } from './wrappedFetch';

import {
  GetEnvelopesForProperty,
  EnvelopeStatus,
  PropertyEnvelope,
  GetEnvelopeChangesResponse
} from '@property-folders/contract/rest/property/envelope';
import { LinkBuilder } from '../util/LinkBuilder';

export class EnvelopeApi {
  static async getExternalUpdates(propertyId: string) {
    const externalUpdates = await EnvelopeApi.listExternalUpdates(propertyId);
    const allUpdates = await Promise.allSettled(
      externalUpdates.map(
        update => WrappedFetch.json<GetEnvelopeChangesResponse>(
          LinkBuilder.restApi(`/envelopes/${update.envelopeId}/changes`))
          .then(upd => ({ envelope: update, ...upd })))
    );

    const arr = [];
    for (const update of allUpdates) {
      if (update.status !== 'fulfilled') {
        console.warn('Failed to fetch envelope:', update.reason);
        continue;
      }

      const { value } = update;
      if (!value) {
        continue;
      }

      arr.push({
        id: value.envelope.envelopeId,
        name: value.agentName,
        position: value.position ?? 'Form 1 Specialist',
        status: 'Pending',
        time: new Date(value.timestampMs),
        metadata: value.metadata,
        chatLink: value.metadata?.epfJobId ? `/form1_dashboard.php?page=view&jobId=${value.metadata.epfJobId}` : undefined,
        updates: value.changes.map(diff => ({
          prettyFieldName: diff.path,
          fieldName: diff.rawPath,
          from: diff.from,
          to: diff.to,
          acceptChange: true,
          desc: diff.desc
        }))
      });
    }

    return arr;
  }

  static async listExternalUpdates(propertyId: string): Promise<PropertyEnvelope[]> {
    return (await EnvelopeApi.getEnvelopesForPropertyFolder(propertyId)).Results
      .filter(t => [EnvelopeStatus.PENDING, EnvelopeStatus.CLAIMED].includes(t.status) && t.destinationPropertyFolderUuid === propertyId && t.payloadDiff.length);
  }

  static async checkForExternalUpdates(propertyId: string): Promise<boolean> {
    return (await EnvelopeApi.listExternalUpdates(propertyId)).length > 0;
  }

  static async getEnvelopesForPropertyFolder(propertyId: string): Promise<GetEnvelopesForProperty> {
    const results = await WrappedFetch.json<GetEnvelopesForProperty>(LinkBuilder.restApi(`/properties/${propertyId}/envelope`), {
      method: 'GET'
    });

    return results ? results : { Results: [] };
  }

  static async applyExternalUpdate({
    propertyId,
    envelopeId,
    updates
  }: {propertyId: string; envelopeId: string; updates: (string | string[])[]}): Promise<void> {
    await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/envelope/merge`), {
      method: 'PUT',
      body: JSON.stringify({
        envelopeId,
        paths: updates
      })
    });
  }
}
