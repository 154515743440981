import { ArrayUtil } from '../util/array';
import { pointsToPixels } from '../util/pdfgen/measurements';
import { TimestampPosition } from '@property-folders/contract';

function sum(values: number[]): number {
  return values.reduce((a, b) => a + b, 0);
}

function getTextWidth(text: string, font: string, ctx: CanvasRenderingContext2D): number {
  ctx.font = font;
  ctx.textBaseline = 'alphabetic';
  return ctx.measureText(text).width;
}

export type Dimension = { width: number, height: number };
type BoundingBox = Dimension & { top: number, left: number, bottom: number, right: number };
type Point = { x: number, y: number };

interface GridInfo {
  colWidths: number[];
  rowHeights: number[];
  margin: number
}

function getCellRectangle(col: number, row: number, colSpan: number, rowSpan: number, grid: GridInfo): BoundingBox {
  const { rowHeights, colWidths, margin } = grid;
  // assumes canvas x,y is top left
  const top = sum(rowHeights.slice(0, row)) + margin;
  const left = sum(colWidths.slice(0, col)) + margin;
  const width = sum(colWidths.slice(col, col + colSpan)) - (2 * margin);
  const height = sum(rowHeights.slice(row, row + rowSpan)) - (2 * margin);
  return {
    top,
    left,
    bottom: top + height,
    right: left + width,
    width,
    height
  };
}

/**
 * Fixed font size - truncate the least amount of text required to fit the space
 */
function fitText(
  text: string,
  font: string,
  ctx: CanvasRenderingContext2D,
  maxWidth: number,
  ellipsis = '...'
): { text: string, width: number } {
  const width = getTextWidth(text, font, ctx);
  if (width <= maxWidth || text.length <= ellipsis.length) {
    return { text, width };
  }

  const index = ArrayUtil.binarySearchMax(
    ArrayUtil.range(text.length),
    idx => getTextWidth(`${text.substring(0, idx)}${ellipsis}`, font, ctx) <= maxWidth
  );
  const newText = `${text.substring(0, index)}${ellipsis}`;
  return {
    text: newText,
    width: getTextWidth(newText, font, ctx)
  };
}

function drawText({
  ctx,
  text: baseText,
  font,
  box,
  baseline = 'alphabetic',
  align = 'left',
  truncate,
  debug
}: {
  ctx: CanvasRenderingContext2D
  text: string,
  font: string,
  box: BoundingBox,
  baseline?: CanvasTextBaseline,
  align?: 'left' | 'right',
  truncate?: boolean,
  debug?: boolean
}) {
  ctx.font = font;
  ctx.fillStyle = 'black';
  ctx.textBaseline = baseline;
  const { width, text } = truncate
    ? fitText(baseText, font, ctx, box.width)
    : { width: getTextWidth(baseText, font, ctx), text: baseText };

  const left = align === 'right'
    ? box.right - width
    : box.left;

  if (debug) {
    ctx.lineWidth = 0.25;
    ctx.strokeStyle = 'red';
    ctx.fillStyle = 'red';
    ctx.strokeRect(box.left, box.top, box.width, box.height);
    ctx.strokeStyle = 'blue';
    ctx.fillStyle = 'blue';
    ctx.strokeRect(left, box.top, width, box.height);
    ctx.fillStyle = 'black';
  }

  ctx.font = font;
  ctx.fillStyle = 'black';
  ctx.textBaseline = baseline;
  ctx.fillText(text, left, box.bottom);
}

function drawImage({
  ctx,
  image,
  box
}: {
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  box: BoundingBox
}) {
  // width = height * ratio
  // height * ratio = width
  // ratio = width / height
  const ratio = image.width / image.height;
  const boxRatio = box.width / box.height;
  if (ratio >= 1) {
    if (ratio > boxRatio) {
      const height = box.width / ratio;
      const heightDiff = box.height - height;
      ctx.drawImage(image, box.left, box.top + heightDiff, box.width, height);
    } else {
      ctx.drawImage(image, box.left, box.top, box.height * ratio, box.height);
    }
  } else {
    ctx.drawImage(image, box.left, box.top, box.width, box.height);
  }
}

function drawLine({
  ctx,
  start,
  end,
  colour,
  thickness
}: {
  ctx: CanvasRenderingContext2D,
  start: Point,
  end: Point,
  colour?: string,
  thickness?: number
}) {
  ctx.strokeStyle = colour || 'black';
  ctx.lineWidth = thickness || 1;
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  ctx.stroke();
}

function getDevicePixelRatio(): number {
  if (typeof window === 'undefined') {
    return 1;
  }

  return window.devicePixelRatio || 1;
}

function prepareCanvas(ctx: CanvasRenderingContext2D, width: number, height: number) {
  const devicePixelRatio = getDevicePixelRatio();
  // assuming a base dpi of 72, scaling by 4x means we can look nice at ~300dpi.
  const scale = 4 * devicePixelRatio;
  ctx.canvas.width = width * scale;
  ctx.canvas.height = height * scale;
  ctx.scale(scale, scale);
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, width, height);
}

/**
 * please only pass one '*'
 */
function divideSpace(spec: ('*' | number)[], availableSpace: number, margin: number): number[] {
  const result: number[] = new Array(spec.length);
  const totalMargin = margin * 2 * spec.length;
  for (let i = 0; i < spec.length; i++) {
    const value = spec[i];
    if (value === '*') continue;
    result[i] = value;
  }

  const remainingSpace = availableSpace - sum(result.map(x => x || 0)) - totalMargin;
  for (let i = 0; i < spec.length; i++) {
    if (spec[i] === '*') {
      result[i] = remainingSpace;
      return result;
    }
  }

  return result;
}

/**
 * draw a compound signature, but restrict its dimensions
 * Appearance should be something like
 * --------------------------
 * <signature       >
 * <________________>
 * <name> <timestamp>
 * --------------------------
 * font size will be fixed, and long names truncated.
 * remaining space above will be devoted to the signature image.
 * @param context render target
 * @param signature image
 * @param name name of the signer
 * @param timestamp timestamp text (usually a date)
 * @param dimensions render to an aspect ratio derived from these dimensions. better image quality if we can render to
 *   2 or 3x the dimensions specified
 * @param textPixelsPerInch ppi for font size calculations
 */
export function drawCompoundSignatureWithinDimensions({
  context: ctx,
  signature,
  name,
  timestamp,
  dimensions: { width, height },
  textPixelsPerInch,
  timestampPosition
}: {
  context: CanvasRenderingContext2D
  signature: HTMLImageElement,
  name: string,
  timestamp: string,
  dimensions: Dimension,
  textPixelsPerInch: number,
  timestampPosition?: TimestampPosition
}) {
  const fontName = 'Arial';
  const fs10pt = pointsToPixels(10, textPixelsPerInch);
  const fontTimestamp = `${fs10pt}px ${fontName}`;
  prepareCanvas(ctx, width, height);

  const timestampWidth = getTextWidth(timestamp, fontTimestamp, ctx);
  const textRowHeight = Math.max(
    2 + fs10pt
  );
  const margin = 2;
  const colWidths = divideSpace(['*', timestampWidth], width, margin);
  const rowHeights = divideSpace(['*', textRowHeight], height, margin);
  const grid: GridInfo = {
    margin,
    colWidths,
    rowHeights
  };
  const boxSignature = getCellRectangle(0, 0, 2, 1, grid);
  const boxTimestampText: BoundingBox = timestampPosition
    ? {
      width: boxSignature.width,
      height: boxSignature.height,
      top: timestampPosition.y,
      bottom: timestampPosition.y + boxSignature.height,
      left: timestampPosition.x,
      right: timestampPosition.x + boxSignature.width
    }
    : getCellRectangle(1, 1, 1, 1, grid);

  const boxNameText = getCellRectangle(0, 1, 1, 1, grid);

  drawText({ ctx, text: name, font: `${fs10pt}px ${fontName}`, box: boxNameText, baseline: 'bottom', truncate: true });
  drawText({
    ctx,
    text: timestamp,
    font: `${fs10pt}px ${fontName}`,
    box: boxTimestampText,
    baseline: 'bottom',
    align: 'left'
  });
  drawImage({ ctx, image: signature, box: boxSignature });
  drawLine({
    ctx,
    start: { x: boxSignature.left, y: boxSignature.bottom },
    end: { x: boxSignature.right, y: boxSignature.bottom },
    colour: 'black',
    thickness: 0.5
  });
}
