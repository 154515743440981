export class StringUtil {
  static tryParseInt(value: string | undefined | null,  def: undefined): number | undefined;
  static tryParseInt(value: string | undefined | null,  def: number): number;
  static tryParseInt(value: string | undefined | null,  def: number | undefined): number | undefined {
    if (!value) {
      return def;
    }

    const parsed = parseInt(value, 10);

    if (parsed == null || isNaN(parsed)) {
      return def;
    }

    return parsed;
  }

  static sanitiseFileName(value: string): string {
    return value.replace(/[^-_ .a-zA-Z0-9]/g, '');
  }
}
