import { HtmlClause, MarkdownClause } from '@property-folders/contract';

export type PartialClause =
  Pick<MarkdownClause, 'markdownClause' | 'title' | 'formCodes' | 'allForms' | 'editable' | 'formCodesRequired'>
  | Pick<HtmlClause, 'htmlClause' | 'title' | 'formCodes' | 'allForms' | 'editable' | 'formCodesRequired'>;

export interface Clause {
  title: string;
  clauseId: number;
  markdownClause?: string;
  htmlClause?: string;
  allForms: boolean;
  formCodes: string[];
  formCodesRequired: string[];
  isGlobal: boolean;
  updateStamp: string
  editable: boolean;
  hash: string;
  conditionallyRequiredWhen?: string;
}

export function isMarkdownClause(f: Clause): f is MarkdownClause {
  return 'markdownClause' in f && !!f.markdownClause;
}

export function isHtmlClause(f: Clause): f is HtmlClause {
  return !isMarkdownClause(f);
}
