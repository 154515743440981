export interface PaddingConfig {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export function padCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, config: PaddingConfig) {
  const width = canvas.width;
  const height = canvas.height;
  const imgData = context.getImageData(0, 0, width, height);

  const newWidth = width + config.left + config.right;
  const newHeight = height + config.top + config.bottom;
  canvas.width = newWidth;
  canvas.height = newHeight;
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.putImageData(imgData, config.left, config.top);
}
