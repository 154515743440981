export class HtmlCharacterCodec {
  private static encodeCharMap = {
    // Sets the default encoding character.
    '&': '&amp;'
  } as const;
  private static decodeCharMap = {
    '&amp;': '&'
  } as const;
  private static encodeRegexp = /&[#]?[x]?[\w]+;/g;
  private static decodeRegexp = new RegExp(`[${Object.keys(this.encodeCharMap)}]`, 'g');

  /**
   * Decode string.
   * @example
   * HtmlCharacterCodec.decode('you &amp; me');
   * // 'you & me
   * @param s String to decode.
   * @returns Decoded string.
   */
  public static decode(s: string) {
    return s.replace(this.encodeRegexp, (def) => {
      // Undefined match will be replaced with the initial match to not throw an error.
      return this.decodeCharMap[def as keyof typeof this.decodeCharMap] ?? def;
    });
  }

  /**
   * Encode string.
   * @example
   * HtmlCharacterCodec.encode('you & me');
   * // 'you &amp; me
   * @param s String to decode.
   * @returns Decoded string.
   */
  public static encode(s: string) {
    return s.replace(this.decodeRegexp, (def) => {
      // Undefined match will be replaced with the initial match to not throw an error.
      return this.encodeCharMap[def as keyof typeof this.encodeCharMap] ?? def;
    });
  }
}
