import { Clause } from '@property-folders/contract/property';

export function removeClausesManagedBy(draft?: Clause[], managedBy: string) {
  if (!draft?.length) return;

  for (let idx = draft.length - 1; idx >= 0; idx--) {
    const clause = draft[idx];
    if ('managedBy' in clause && clause.managedBy === managedBy) {
      draft.splice(idx, 1);
    }
  }
}
