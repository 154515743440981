/**
 * Set all pixels over a given lightness threshold to be transparent
 * @param canvas
 * @param context
 * @param threshold lightness threshold from 0 to 255
 */
export function eraseBackground(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, threshold = 250) {
  const width = canvas.width;
  const height = canvas.height;
  const imgData = context.getImageData(0, 0, width, height);
  const pixels = imgData.data;

  const channelCount = 4;
  const isLightEnough = (x: number, y: number) => {
    const idxPixel = (width * y + x) * channelCount;
    return (pixels[idxPixel] + pixels[idxPixel+1] + pixels[idxPixel+2]) / 3 > threshold;
  };

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      if (isLightEnough(x, y)) {
        pixels[((width * y + x) * channelCount)+3] = 0;
      }
    }
  }

  context.putImageData(imgData, 0, 0);
}
