import { Predicate } from '../../predicate';
import { createElement, Fragment } from 'react';

export function rawTextToHtmlParagraphs(text: string) {
  return text.split(/\n\n+/).filter(Predicate.isTruthy).map(para => {
    return createElement('p', {}, para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length -1
      ? createElement(Fragment, {}, [line,createElement('br')])
      : line
    ));
  });
}
