
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';

export class SigningApi {
  public static async resendSigningLink(signingSessionId: string, partyId: string) {
    await WrappedFetch.bare(LinkBuilder.restApi(`/signing-sessions/${signingSessionId}/parties/${partyId}/remote-sessions`), {
      method: 'POST'
    });
  }

  public static async sendDelayedDistributions(propertyId: string, signingSessionId: string) {
    await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/signing-sessions/${signingSessionId}/send-delayed`), {
      method: 'POST'
    });
  }
  public static async sendDelayedDistributionToParty(propertyId: string, signingSessionId: string, partyId: string) {
    await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/signing-sessions/${signingSessionId}/send-delayed/${partyId}`), {
      method: 'POST'
    });
  }

  public static async resendCompletedLink(propertyId: string, signingSessionId: string, partyId: string) {
    await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/signing-sessions/${signingSessionId}/resend-completed/${partyId}`), {
      method: 'POST'
    });
  }
}
