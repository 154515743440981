import { FormStateRequirementDescriptor, FormTypes } from '../yjs-schema/property/form';
import { find } from 'lodash';

import { PurchaserParty, SigningAuthorityType, VendorParty, PartyType, FormInstance, FormStates, VendorPartyJoint, jointTypes } from '@property-folders/contract';

import { Predicate } from '../predicate';
import { jointPartyConfig, partyFieldConfig } from './formatting/constants';
import { determineFormState } from '../yjs-schema/property';

export function determineIfFormPassesConditions(
  suggestionFormCode: keyof typeof FormTypes,
  formRequirements?: FormStateRequirementDescriptor,
  metaFormStates?: FormStates,
  parentMetaFormStates?: FormStates // Parent here can refer to the root key, in case were checking document completion of the root document (eg, contracts allowed, when parent has RSAA. Does not mean parent ydoc)
) {
  let alreadyPresent = false;
  let dependencyPassed = false;
  let failedInstances: (FormInstance | string)[] = [];
  if (formRequirements && (metaFormStates||parentMetaFormStates)) {
    const defnParent = FormTypes[suggestionFormCode]?.formFamily;
    const instances = metaFormStates && typeof metaFormStates === 'object' && metaFormStates?.[defnParent]?.instances;
    const parentInstances = parentMetaFormStates && typeof parentMetaFormStates === 'object' && parentMetaFormStates?.[defnParent]?.instances;
    const evaluatePresence = (i: FormInstance) => {
      return i.formCode === suggestionFormCode || FormTypes[i.formCode].formFamily === suggestionFormCode; // Not currently checking if a form exists but is expired and allowing it
    };
    if (instances && find(instances, evaluatePresence)) {
      alreadyPresent = true;
    }
    if (!alreadyPresent && parentInstances && find(parentInstances, evaluatePresence) ) {
      alreadyPresent = true;
    }

    failedInstances = Object.entries(formRequirements).map(([formCode, stateChecks]) => {
      const defnParent = FormTypes[formCode]?.formFamily;
      const myInstances = (metaFormStates && typeof metaFormStates === 'object' && metaFormStates?.[defnParent]?.instances) || [];
      const parentInstances = (parentMetaFormStates && typeof parentMetaFormStates === 'object' && parentMetaFormStates?.[defnParent]?.instances) || [];
      const instances = [...parentInstances, ...myInstances];
      if (instances.length > 0) {
        // Only evaluating primary (first) instance for now
        const instance = instances[0];
        // Only checking as whitelist for now
        return (!(determineFormState(instance) in stateChecks) && instance) || undefined; // Leaning on non nullable rather than false
      } else {
        return defnParent; // Returning an inverse here. Returned trues result in non-zero length and thus a failure
      }
    }).filter(Predicate.isTruthy);
    dependencyPassed = window.fnDev.noSigSuggestion || failedInstances.length === 0;
  }
  return { alreadyPresent, dependencyPassed, failedInstances };
}

export function getVendorConfig(vendor: VendorParty | PurchaserParty | VendorPartyJoint) {
  const { partyType, authority } = (vendor || {}) as {partyType?: PartyType, authority?: SigningAuthorityType};
  if (!(partyType && authority)) return {};
  const configuration = jointTypes.includes(partyType)
    ? jointPartyConfig[partyType]
    : (partyType && authority && partyFieldConfig[partyType][authority]);
  return configuration || {};
}
