import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import { FormCode } from '@property-folders/contract';
import { useInfiniteQuery } from '@tanstack/react-query';
import { cloneSimple } from '../util/cloneSimple';
import { Clause, PartialClause } from '../types/Clause';

export interface ListClausesForEntityRequest {
  entityUuid: number | string;
  query: {
    searchTerm?: string;
    pageNumber?: number;
    pageSize?: number;
  }
}

export class ClausesApi {
  static async listClausesForFormAndProperty(opts: {
    formCode: FormCode | string;
    markdown?: boolean; // default true
    html?: boolean; // default false
    conditional?: boolean; // default false
    propertyId: string;
  }) {
    const {
      propertyId,
      formCode,
      markdown = true,
      html = false,
      conditional = false
    } = opts;
    return await WrappedFetch.json<{ results: Clause[] }>(
      LinkBuilder.restApi(`/properties/${propertyId}/clauses/form/${formCode}?markdown=${markdown}&html=${html}&conditional=${conditional}`),
      {
        method: 'GET'
      }
    );
  }
  static async listClausesForForm(opts: {
    formCode: FormCode | string;
    markdown?: boolean; // default true
    html?: boolean; // default false
    conditional?: boolean; // default false
  }) {
    return await WrappedFetch.json<{ results: Clause[] }>(
      LinkBuilder.restApi(`/clauses/form/${opts.formCode}?markdown=${opts.markdown ?? 'true'}&html=${opts.html ?? 'false'}&conditional=${opts.conditional ?? 'false'}`),
      {
        method: 'GET'
      }
    );
  }

  static async listRequiredClausesForFormAndProperty({ propertyId, formCode }: {propertyId: string, formCode: FormCode | string}) {
    return await WrappedFetch.json<{
      results: Clause[]
    }>(LinkBuilder.restApi(`/properties/${propertyId}/clauses/form/${formCode}/required`), {
      method: 'GET'
    });
  }

  static async listRequiredClausesForForm(formCode: FormCode | string) {
    return await WrappedFetch.json<{ results: Clause[] }>(LinkBuilder.restApi(`/clauses/form/${formCode}/required`), {
      method: 'GET'
    });
  }

  static async listClausesForEntity(opts: ListClausesForEntityRequest) {
    const { pageNumber = 1, pageSize = 10, searchTerm } = opts.query;
    return await WrappedFetch.json<{ results: Clause[]; pageNumber: number }>(
      LinkBuilder.restApi(`/clauses/entity/${opts.entityUuid}?pageNumber=${pageNumber}&pageSize=${pageSize}${searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : ''}`),
      {
        method: 'GET'
      }
    );
  }

  static async addClauseForEntity(clause: PartialClause, entityUuid: string) {
    clause.formCodes = clause.allForms ? [] : (clause.formCodes ?? []);
    clause.formCodesRequired = clause.allForms ? [] : (clause.formCodesRequired ?? []);

    return await WrappedFetch.json(LinkBuilder.restApi(`/clauses/entity/${entityUuid}`), {
      method: 'POST',
      body: JSON.stringify(clause),
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  static async updateClauseForEntity(clause: Clause) {
    clause.formCodes = clause.allForms ? [] : (clause.formCodes ?? []);
    clause.formCodesRequired = clause.allForms ? [] : (clause.formCodesRequired ?? []);

    return await WrappedFetch.json(LinkBuilder.restApi(`/clauses/${clause.clauseId}`), {
      method: 'PUT',
      body: JSON.stringify(clause),
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  static async deleteClauseForEntity(clause: Pick<Clause, 'clauseId'>) {
    return await WrappedFetch.json(LinkBuilder.restApi(`/clauses/${clause.clauseId}`), {
      method: 'DELETE'
    });
  }

  static useInfiniteEntityClause(defaultParams: ListClausesForEntityRequest) {
    return useInfiniteQuery(
      ['clauses', 'entity', 'list', defaultParams.entityUuid, defaultParams.query.searchTerm],
      async context => {
        return await ClausesApi.listClausesForEntity(context.pageParam || defaultParams);
      },
      {
        getNextPageParam: (lastPage, _) => {
          if (!lastPage?.results[0]?.clauseId) {
            return undefined;
          }

          const p = cloneSimple(defaultParams);
          p.query.pageNumber = lastPage.pageNumber + 1;
          return p;
        }
      }
    );
  }

  static async getUpdates(clauses: { clauseId: string | number; hash: string }[]) {
    return await WrappedFetch.json<{
      updates: {
        clauseId: number;
        title: string;
        markdownClause: string;
        htmlClause: string;
        hash: string;
      }[]
    }>(LinkBuilder.restApi('/clauses/updates'), {
      method: 'POST',
      body: JSON.stringify({
        clauses: clauses.map(c => ({
          clauseId: String(c.clauseId),
          hash: c.hash
        }))
      }),
      headers: {
        'content-type': 'application/json'
      }
    });
  }
}
