export class FileLoadError extends Error {
  constructor(
    public fileId: string,
    public contentType: string
  ) {
    super(`Could not load contents for file ${fileId} (${contentType})`);
  }
}

export class MissingFieldDefinitionError extends Error {
  constructor(
    public fieldId: string
  ) {
    super(`Could not find matching field definition for field id ${fieldId}`);
  }
}

export class MissingPartyDetailsError extends Error {
  constructor(
    public partyId: string,
    public fieldType: string
  ) {
    super(`Could not find matching party ${fieldType} definition for party id ${partyId}`);
  }
}
