import React from 'react';

export function clickNoBubble<T = HTMLElement>(fn?: () => void) {
  return (e: React.MouseEvent<T>) => {
    e.preventDefault();
    e.stopPropagation();
    if (fn) {
      return fn();
    }
  };
}
