import { PurchaserPortalSharedDocument } from '@property-folders/contract';

export function mapDocumentTypeToFriendlyName(type?: PurchaserPortalSharedDocument['documentType']) {
  switch (type) {
    case undefined:
      return '';

    case 'brochure':
    case 'contract':
    case 'form-1':
    case 'prescribed-notice':
    default:
      return type.split('-').map(w => w[0].toUpperCase() + w.slice(1)).join(' ');
  }
}
