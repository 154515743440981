import {
  Annexure,
  ContentType,
  FileRef,
  ManifestData,
  ManifestType,
  MaterialisedPropertyData,
  UploadType
} from '@property-folders/contract';

import { processPdf } from './pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '../offline/fileStorage';
import { FileSync } from '../offline/fileSync';
import { v4 } from 'uuid';
import { Snapshot, UpdateFn } from 'immer-yjs/src';
import { AnyAction, Store } from 'redux';
import { AnnexuresSurgeon } from './annexures-surgeon';
import { DetermineReplacedRemovedLockedResult } from './dataExtractTypes';
import { Doc } from 'yjs';

type ImmerCallback<T extends Snapshot> = (userFunc: UpdateFn<T[]>) => void;

export async function deleteAnnexure (
  deleted: Annexure,
  usingPrevious:boolean,
  annexures: DetermineReplacedRemovedLockedResult<Annexure>[],
  updateAnnexures: ImmerCallback<Annexure> | undefined,
) {
  if (!deleted?.id) {
    return;
  }
  if (deleted?.noEditRemove || deleted?.noEditRemove) {
    console.warn('Annexure has been marked non-removable');
    return;
  }
  const fileRef = deleted.id;
  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, usingPrevious ? annexures : []);
    surgeon.remove({ id: deleted.id });
    surgeon.updateLabels();
  });

  // if the annexure was created from an uploaded document, we shouldn't be removing the file
  if (!deleted.preserveFile) {
    await FileStorage.delete(fileRef);
  }
}

export async function undoReplacementAnnexure({
  replacementAnnexureToBeRemoved,
  updateAnnexures
}: {
  replacementAnnexureToBeRemoved: Annexure,
  updateAnnexures: ImmerCallback<Annexure> | undefined,
}) {
  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, []);
    surgeon.restoreReplacedAnnexureInVariation({ replacementAnnexureToBeRemoved });

  });
}

export function createAnnexureFromUploadedDocument({
  file,
  name,
  uploadType,
  updateAnnexures,
  replace,
  managed,
  binding,
  orderList,
  uploadTypeUnique
}: {
  file: FileRef,
  name: string,
  uploadType?: UploadType,
  updateAnnexures: ImmerCallback<Annexure> | undefined,
  replace?: FileRef | string,
  managed?: boolean,
  binding?: {
    path: string,
    root: string
  },
  orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
  // eliminate existing annexures with the same type but unexpected id
  uploadTypeUnique?: boolean
}) {
  const replaceId = replace
    ? typeof replace === 'string'
      ? replace
      : replace.id
    : undefined;

  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, orderList || []);
    surgeon.replace({
      replaceId,
      annexure: {
        id: file.id,
        contentType: file.contentType,
        name,
        preserveFile: true,
        uploadType,
        managed,
        binding
      }
    });
    if (uploadTypeUnique && uploadType) {
      surgeon.removeAllOfType({ type: uploadType, keepId: file.id });
    }
    surgeon.updateLabels();
  });

  return file.id;
}

export async function createAnnexure (
  { file,
    idx,
    linkedFormId,
    coversheetText,
    property,
    formId,
    formCode,
    setErrorMessage,
    updateAnnexures,
    fileSync,
    nameOverride,
    noEditRemove,
    store,
    orderList,
    ydoc,
    replaceInVariation
  } : {
    file: File | Response,
    idx?: number|undefined,
    linkedFormId: string|undefined,
    coversheetText: string|undefined,
    property: MaterialisedPropertyData | undefined,
    formId: string,
    formCode: string,
    setErrorMessage?: (error: string)=>void,
    updateAnnexures?: ImmerCallback<Annexure> | undefined,
    updateAnnexureLabelsDraft?: (annexuresImmerDraft: Annexure[])=>void | undefined,
    fileSync: FileSync | undefined,
    nameOverride?: string,
    noEditRemove?: boolean,
    store: Store<unknown,AnyAction>,
    orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
    ydoc: Doc|undefined,
    replaceInVariation?: Annexure
  }
) {
  if (!property?.id) return;

  const fileBytes = await file.arrayBuffer();
  const pdf = await processPdf(new Uint8Array(fileBytes));
  const fileUrlSegments = file instanceof Response ? file.url?.split('/') : undefined;
  const fileName = nameOverride ?? (file instanceof File ? file.name?.replace(/\.[^/.]+$/, '') : fileUrlSegments && fileUrlSegments[fileUrlSegments.length-1]?.replace(/\.[^/.]+$/, '')) ?? 'Unknown name';
  if (!pdf?.pdf || pdf?.isEncrypted) {
    setErrorMessage?.(`Failed to attach ${fileName}. Please make sure PDF's are not encrypted and try again`);
    return;
  }

  const annexureId = v4();
  const fileid = annexureId;

  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, orderList || []);
    const replacedLabel = replaceInVariation?.label;
    const replacedId = replaceInVariation?.id;

    surgeon.add({
      index: replaceInVariation?.id ? 0 : -1,
      annexure: {
        id: annexureId,
        contentType: file.type,
        name: fileName,
        ...(replacedId ? { replacingId: replacedId } : {}),
        ...(replacedLabel ? { label: replacedLabel } : {}),
        linkedFormId,
        coversheetText,
        ...(noEditRemove?{ noEditRemove }:undefined)
      }
    });
    if (replacedId) {
      if (!replacedLabel) {
        throw new Error('Please provide an existing annexure definition');
      }
      surgeon.remove({ id: replacedId, replacedBy: annexureId });
    }
    surgeon.updateLabels();

  });

  const manifestData: ManifestData = {
    manifestType: ManifestType.None
  };

  await FileStorage.write(
    fileid,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([pdf.pdf], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId: property.id,
        formId,
        formCode
      }
    },
    { store, ydoc },
    manifestData,
    undefined,
  );

  FileSync.triggerSync(fileSync);

  return annexureId;
}

export function updateAnnexureLabelsImmer(draft: Annexure[], orderList?: DetermineReplacedRemovedLockedResult<Annexure>[]) {
  new AnnexuresSurgeon(draft, orderList || []).updateLabels();
}
