import { PDFDocument } from 'pdf-lib';
import { Annexure } from '@property-folders/contract';
import { FileStorage } from '../offline/fileStorage';
import { Predicate } from '../predicate';
import { stitchToDoc } from '../util/pdf';

export async function applyAnnexures(doc: PDFDocument, annexures: Annexure[]) {
  if (!annexures.length) return;

  const annexureData = (
    await Promise.all(annexures
      .map(async a => {
        const blob = await FileStorage.readFileOnly(a.id);
        if (!blob) return undefined;
        return await blob.arrayBuffer();
      })
    )
  ).filter(Predicate.isNotNull);

  return stitchToDoc(doc, annexureData);
}
