export const saleContractNonUniqueWatchPaths = {
  property: [
    ['saleAddrs'],
    ['saleTitles'],
    ['titleDivision']
  ],
  vendor: [['vendors'],['primaryVendor']],
  purchaser: [
    ['purchasers'],
    ['primaryPurchaser']
  ],
  contractGst: [
    ['vendorGst'],
    ['contractPrice', 'inputTaxed'],
    ['contractPrice', 'gstIncluded'],
    ['contractPrice', 'marginApplied']
  ],
  contractPrice: [
    ['contractPrice', 'purchasePrice'],
    ['contractPrice', 'purchaseGst'],
    ['contractPrice', 'deposit'],
    ['contractPrice', 'depositPayAt'],
    ['contractPrice', 'depositDateOther']
  ],
  contractSettlement: [['contractSettlement']],
  contractSchedule: [
    ['chattels'],
    ['exclusions'],
    ['saaTenant', 'tenantEnable'],
    ['tenantsCollect'],
    ['encroach'],
    ['encroachEnable'],
    ['notiWorks'],
    ['notiWorksEnable'],
    ['vendorWorks'],
    ['cgtEnable'],
    ['gstWithholdEnable'],
    ['pool'],
    ['contractSchedule', 'noForm1NoCoolMatters']
  ],
  contractSpecialFinance: [
    ['contractSpecial', 'financeRequired'],
    ['contractSpecial', 'financeDeadline'],
    ['contractSpecial', 'financeDeadlineTime'],
    ['contractSpecial', 'financeAmount'],
    ['contractSpecial', 'financeInterestRate'],
    ['contractSpecial', 'financeProperties']
  ],
  contractSpecialPurchaserSale: [
    ['contractSpecial', 'purchaserSaleRequired'],
    ['contractSpecial', 'purchaserSaleContractDeadline'],
    ['contractSpecial', 'purchaserSaleSettlementDeadline'],
    ['contractSpecial', 'saleSettleAlsoRequired'],
    ['contractSpecial', 'salePropertySingleLine'],
    ['contractSpecial', 'purchaserSaleMinimumPrice']
  ],
  contractOther: [['contractOther']]
};

export const saleContractNonUniqueWatchPathsFlat = Object.values(saleContractNonUniqueWatchPaths).flat();