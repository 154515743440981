import { padCanvas, PaddingConfig } from './pad-canvas';
import { trimCanvas } from './trim-canvas';

function renderWithCanvasContext(renderFn: (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => void, opts?: {
  width?: number,
  height?: number,
  trim?: boolean,
  padding?: PaddingConfig
}) {
  const canvas = document.createElement('canvas');
  try {
    canvas.width = opts?.width || 300;
    canvas.height = opts?.height || 300;
    const context = canvas.getContext('2d', {
      // false = user agent may use the GPU, which is worse for read-heavy operations like trimming
      // true = user agent will probably use software mode, which should be better for trimming
      willReadFrequently: opts?.trim || false
    });
    if (!context) {
      console.log('no context!');
      return undefined;
    }

    renderFn(canvas, context);

    if (opts?.trim) {
      trimCanvas(canvas, context);
    }

    if (opts?.padding) {
      padCanvas(canvas, context, opts.padding);
    }

    return canvas.toDataURL('image/png');
  } finally {
    canvas.remove();
  }
}

export function renderText(opts: {
  text?: string,
  canvas?: {
    height?: number,
    width?: number
  },
  font?: {
    name: string,
    size: number
  },
  padding?: PaddingConfig
}): string | undefined {
  const text = opts.text;
  const fontName = opts.font?.name || 'Helvetica';
  const fontSize = opts.font?.size || 72;

  return renderWithCanvasContext((canvas, context) => {
    if (!text) {
      // doing it here at least means we end up with a blank canvas
      return;
    }
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'black';
    context.font = `${fontSize}px ${fontName}`;
    context.fillText(text, (canvas.width / 20), (canvas.height / 3) * 2, canvas.width - 100);
  }, {
    width: opts.canvas?.width || 800,
    height: opts.canvas?.height || 100,
    trim: true,
    padding: opts.padding
  });
}

//chrome will not load a font unless its drawn on a canvas of greater than 100px width
export function preloadFonts(names: string[]) {
  for (const name of names) {
    renderText({
      text: '.',
      canvas: {
        height: 10,
        width: 101
      },
      font: {
        size: 72,
        name: name
      }
    });
  }
}

export function getTrimmedCanvasCopy(canvas: HTMLCanvasElement) {
  const copy = document.createElement('canvas');
  copy.width = canvas.width;
  copy.height = canvas.height;
  const copyContext = copy.getContext('2d', { willReadFrequently: true });
  if (!copyContext) {
    return copy;
  }
  copyContext.drawImage(canvas, 0, 0);
  trimCanvas(copy, copyContext);
  return copy;
}
