import * as Y from 'yjs';
import { ensureFormStatesInstances, FormTypes } from '../yjs-schema/property/form';
import { v4 } from 'uuid';
import { applyMigrationsV2 } from '../yjs-schema';
import { FormCodeUnion, FormOrderState, FormOrderType, TransactionMetaData } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { FormUtil } from './form';

export async function handleNewFormOrderInternal(ydoc: Y.Doc | undefined, formCode: string, entityId: number): Promise<{ id: string, migration: Uint8Array } | undefined> {
  if (!(formCode in FormTypes)) {
    throw new Error('Invalid form code');
  }
  if (!(ydoc && formCode in FormTypes)) {
    console.warn('cannot create new form', !!ydoc, formCode in FormTypes);
    return undefined;
  }

  const formType = FormTypes[formCode];
  if (!formType.orderable) {
    throw new Error('Form type is not orderable');
  }

  if (!entityId) {
    throw new Error('No fulfiller for form type');
  }

  const fam = formType.formFamily;
  const newId = v4();

  const migration = applyMigrationsV2<TransactionMetaData>({
    doc: ydoc,
    docKey: PropertyRootKey.Meta, // Ordered forms do not yet support sublineages
    typeName: 'Property',
    migrations: [{
      name: 'Create new form order',
      fn: state => {
        if (!FormUtil.canAddForm(state.formStates ?? {}, formCode)) {
          console.warn('cannot add form', state.formStates, formCode);
          return false;
        }
        const instances = ensureFormStatesInstances(state, fam);
        instances.push({
          formCode: (formCode as FormCodeUnion),
          id: newId,
          created: Date.now(),
          modified: Date.now(),
          order: {
            type: FormOrderType.Agent,
            state: FormOrderState.ClientOrdering,
            filler: {
              entityId,
              system: 'EPF'
            }
          }
        });
      }
    }]
  });

  return migration ? { id: newId, migration } : undefined;
}
